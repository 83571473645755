import React from "react";
import { Link } from "react-router-dom";
import "./../css/Ciro.css";

const Ciro = () => {
  return (
    <div className="dashboard-container">
      <h1>Raporlar</h1>
      <div className="dashboard-links">
        <Link to="/gunlukciro" className="dashboard-link">
          <div className="dashboard-card">
            <h2>Günlük Rapor</h2>
            <p>Bugünün ciro ve kategori analizini gösterir.</p>
          </div>
        </Link>
        <Link to="/haftalikciro" className="dashboard-link">
          <div className="dashboard-card">
            <h2>Haftalık Rapor</h2>
            <p>Bu haftanın ciro ve kategori analizini gösterir.</p>
          </div>
        </Link>
        <Link to="/aylikciro" className="dashboard-link">
          <div className="dashboard-card">
            <h2>Aylık Rapor</h2>
            <p>Bu ayın ciro ve kategori analizini gösterir.</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Ciro;
