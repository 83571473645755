import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { db } from "../firebase";
import balık from "../images/balık.jpg";
import meze from "../images/Meze.jpg";
import alkol from "../images/alkol.jpg";
import icecek from "../images/icecekler.jpg";
import kofte from "../images/kofte.jpg";
import makarna from "../images/makarna.jpg";
import et from "../images/et.jpg";
import salata from "../images/salad.jpg";
import susi from "../images/susi.jpg";
import corba from "../images/corba.jpg";
import arasicak from "../images/arasicak.jpg";
import "./../css/Card.css"; // Kartların stilini belirlemek için gerekli CSS dosyasını import edin

const CardList = ({ dil, masa }) => {
  return (
    <div className="card-list">
      <h1>
        {dil === "en"
          ? "MENUS"
          : dil === "ru"
          ? "МЕНЮ"
          : dil === "de"
          ? "MENÜS"
          : dil === "ar"
          ? "قوائمنا"
          : "MENÜLERİMİZ"}
      </h1>

      <Link to={`/balik/${masa}/${dil}`} className="custom-card">
        <img src={balık} alt="Kart 2" />
        <div className="card-content">
          <h3>
            <b>
              {dil === "en"
                ? "FISH"
                : dil === "ru"
                ? "РЫБА"
                : dil === "de"
                ? "FISCHE"
                : dil === "ar"
                ? "سمك"
                : "BALIKLAR"}
            </b>
          </h3>
        </div>
      </Link>

      <Link to={`/et/${masa}/${dil}`} className="custom-card">
        <img src={et} alt="Kart 2" />
        <div className="card-content">
          <h3>
            <b>
              {dil === "en"
                ? "MEATS"
                : dil === "ru"
                ? "МЯСО"
                : dil === "de"
                ? "FLEISCH"
                : dil === "ar"
                ? "لحمة"
                : "ETLER"}
            </b>
          </h3>
        </div>
      </Link>
      <Link to={`/makarna/${masa}/${dil}`} className="custom-card">
        <img src={makarna} alt="Kart 2" />
        <div className="card-content">
          <h3>
            <b>
              {dil === "en"
                ? "PASTAS"
                : dil === "ru"
                ? "ПАСТЫ"
                : dil === "de"
                ? "PASTAS"
                : dil === "ar"
                ? "الباستا"
                : "MAKARNALAR"}
            </b>
          </h3>
        </div>
      </Link>
      <Link to={`/arasicak/${masa}/${dil}`} className="custom-card">
        <img src={arasicak} alt="Kart 2" />
        <div className="card-content">
          <h3>
            <b>
              {dil === "en"
                ? "SEARCH HOT"
                : dil === "ru"
                ? "ПОИСК ГОРЯЧИХ"
                : dil === "de"
                ? "SUCHE HEISS"
                : dil === "ar"
                ? "بحث ساخن"
                : "ARA SICAKLAR"}
            </b>
          </h3>
        </div>
      </Link>
      <Link to={`/corba/${masa}/${dil}`} className="custom-card">
        <img src={corba} alt="Kart 2" />
        <div className="card-content">
          <h3>
            <b>
              {dil === "en"
                ? "SOUP"
                : dil === "ru"
                ? "СУП"
                : dil === "de"
                ? "SUPPE"
                : dil === "ar"
                ? "حساء"
                : "ÇORBALAR"}
            </b>
          </h3>
        </div>
      </Link>
      <Link to={`/meze/${masa}/${dil}`} className="custom-card">
        <img src={meze} alt="Kart 3" />
        <div className="card-content">
          <h3>
            <b>
              {dil === "en"
                ? "APPETIZERS"
                : dil === "ru"
                ? "ЗАКУСКИ"
                : dil === "de"
                ? "VORSPEISEN"
                : dil === "ar"
                ? "مقبلات"
                : "MEZELER"}
            </b>
          </h3>
        </div>
      </Link>
      <Link to={`/salata/${masa}/${dil}`} className="custom-card">
        <img src={salata} alt="Kart 3" />
        <div className="card-content">
          <h3>
            <b>
              {dil === "en"
                ? "SALADS"
                : dil === "ru"
                ? "САЛАТЫ"
                : dil === "de"
                ? "SALATE"
                : dil === "ar"
                ? "سلطة"
                : "SALATALAR"}
            </b>
          </h3>
        </div>
      </Link>
      <Link to={`/susi/${masa}/${dil}`} className="custom-card">
        <img src={susi} alt="Kart 3" />
        <div className="card-content">
          <h3>
            <b>
              {dil === "en"
                ? "SUSHI"
                : dil === "ru"
                ? "СУШИ"
                : dil === "de"
                ? "SUSHI"
                : dil === "ar"
                ? "سوشي"
                : "SUŞİLER"}
            </b>
          </h3>
        </div>
      </Link>
      <Link to={`/alkol/${masa}/${dil}`} className="custom-card">
        <img src={alkol} alt="Kart 3" />
        <div className="card-content">
          <h3>
            <b>
              {dil === "en"
                ? "ALCOHOLIC DRINKS"
                : dil === "ru"
                ? "АЛКОГОЛЬНЫЕ НАПИТКИ"
                : dil === "de"
                ? "ALKOHOLISCHE GETRÄNKE"
                : dil === "ar"
                ? "مشروبات كحولية"
                : "ALKOLLÜ İÇECEKLER"}
            </b>{" "}
          </h3>
        </div>
      </Link>

      <Link to={`/icecek/${masa}/${dil}`} className="custom-card">
        <img src={icecek} alt="Kart 3" />
        <div className="card-content">
          <h3>
            <b>
              {dil === "en"
                ? "DRINKS"
                : dil === "ru"
                ? "НАПИТКИ"
                : dil === "de"
                ? "GETRÄNKE"
                : dil === "ar"
                ? "مشروبات"
                : "İÇECEKLER"}
            </b>
          </h3>
        </div>
      </Link>
    </div>
  );
};

export default CardList;
