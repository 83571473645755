import React from "react";
import NavigationBar from "../components/Navbar";
import Card from "../components/Card";
import "./../css/Home.css"; // Import the CSS file
import { useParams } from 'react-router-dom';

const Home = () => {
  const { masa, dil } = useParams();
  console.log(masa, dil);
  return (
    <div className="home-container">
      <NavigationBar masa={masa} dil={dil} />
      <div className="card-container">
        <Card masa={masa} dil={dil}/>
      </div>
    </div>
  );
};

export default Home;