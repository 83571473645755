import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  onSnapshot,
  where,
  orderBy,
  doc,
  updateDoc,
  deleteDoc,
  getDocs,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import Modal from "react-modal";
import "./../css/AdminMasaSiparis.css";
import sound from "../sounds/order-sound.mp3";
import { startOfDay, endOfDay } from "date-fns";
import { useReactToPrint } from "react-to-print";
const masalar = [
  "İç Mekan-1",
  "İç Mekan-2",
  "İç Mekan-3",
  "İç Mekan-4",
  "İç Mekan-5",
  "İç Mekan-6",
  "İç Mekan-7",
  "İç Mekan-8",
  "Dış Mekan-1",
  "Dış Mekan-2",
  "Dış Mekan-3",
  "Dış Mekan-4",
  "Dış Mekan-5",
  "Dış Mekan-6",
  "Dış Mekan-7",
  "Dış Mekan-8",
  "Dış Mekan-9",
  "Dış Mekan-10",
  "Dış Mekan-11",
  "Dış Mekan-12",
  "Dış Mekan-13",
  "Çardak-1",
  "Çardak-2",
  "Çardak-3",
];

Modal.setAppElement("#root");

const MasaSiparis = () => {
  const [updatedMasalar, setUpdatedMasalar] = useState({});
  const [selectedMasa, setSelectedMasa] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [orderDetails, setOrderDetails] = useState([]);
  const [processing, setProcessing] = useState(false);
  const [pay, setPay] = useState(false);
  const [newOrders, setNewOrders] = useState([]);
  const [soundPlayed, setSoundPlayed] = useState(false);
  const [latestOrder, setLatestOrder] = useState(null);
  const [paidOrders, setPaidOrders] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [editableOrder, setEditableOrder] = useState(null);
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [newOrderModalIsOpen, setNewOrderModalIsOpen] = useState(false);
  const [newOrder, setNewOrder] = useState({
    orijinalDil: "",
    price: "",
    quantity: "",
    note: "",
  });
  const [filteredMenuItems, setFilteredMenuItems] = useState(menuItems);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const componentRef = React.useRef(null);

  const printFn = useReactToPrint({
    contentRef: componentRef,
    documentTitle: "Sipariş Detayları",
  });
  const getMenuItems = async () => {
    try {
      const menuItemsSnapshot = await getDocs(collection(db, "menutr")); // menutr koleksiyonunu getir
      const menuItems = menuItemsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      return menuItems; // Menü öğelerini döndür
    } catch (error) {
      console.error("Menü öğeleri alınırken hata oluştu:", error);
      return [];
    }
  };
  useEffect(() => {
    const fetchMenuItems = async () => {
      const data = await getMenuItems();
      setMenuItems(data);
      setFilteredMenuItems(data); // İlk başta tüm menü öğelerini göster
    };

    fetchMenuItems();
  }, []);

  const handleMenuSearch = (event) => {
    const query = event.target.value.toLowerCase();
    const filtered = menuItems.filter((menu) =>
      menu.orijinalDil.toLowerCase().includes(query)
    );
    setFilteredMenuItems(filtered);
  };

  const playNotificationSound = () => {
    const audio = new Audio(sound);
    audio.play();
  };
  const hesaplaVeKaydetCiro = async () => {
    const todayStart = startOfDay(new Date());
    const todayEnd = endOfDay(new Date());

    const q = query(
      collection(db, "orders"),
      where("finishTime", ">=", todayStart),
      where("finishTime", "<=", todayEnd),
      where("pay", "==", true) // Ödemesi alınmış siparişler
    );

    let totalCiro = 0;

    const snapshot = await getDocs(q);
    snapshot.forEach((doc) => {
      const data = doc.data();
      if (data.orders && Array.isArray(data.orders)) {
        data.orders.forEach((order) => {
          totalCiro += order.price * order.quantity; // Ürün fiyatı ve miktarı ile çarpıyoruz
        });
      }
    });

    // Günün tarihini belirle
    const todayDate = new Date().toLocaleDateString("en-CA");

    // Ciroyu ciro koleksiyonuna kaydet
    const ciroDocRef = doc(db, "ciro", todayDate);
    await setDoc(ciroDocRef, {
      totalCiro: totalCiro,
    });

    console.log(`Toplam ciro: ${totalCiro} TL olarak kaydedildi.`);
  };
  useEffect(() => {
    // Menüleri menutr koleksiyonundan çek
    const fetchMenuItems = async () => {
      const q = query(collection(db, "menutr"));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const items = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          items.push({ id: doc.id, ...data });
        });
        setMenuItems(items);
      });
      return () => unsubscribe();
    };

    fetchMenuItems();
  }, []);
  useEffect(() => {
    if (orderDetails.length > 0) {
      const total = orderDetails.reduce(
        (acc, order) => acc + order.price * order.quantity,
        0
      );
      setTotalAmount(total);
    }
  }, [orderDetails]);
  useEffect(() => {
    const todayStart = startOfDay(new Date());
    const todayEnd = endOfDay(new Date());

    const q = query(
      collection(db, "orders"),
      where("finishTime", ">=", todayStart),
      where("finishTime", "<=", todayEnd),
      where("pay", "==", false),
      orderBy("finishTime", "asc")
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const updates = {};
      const newOrdersArray = [];
      let latestOrder = null;

      snapshot.forEach((doc) => {
        const data = doc.data();
        if (data.orders && Array.isArray(data.orders)) {
          data.orders.forEach((order) => {
            if (order.masaNo) {
              updates[order.masaNo] = {
                ...data,
                id: doc.id,
              };

              if (data.processing === false) {
                newOrdersArray.push({
                  ...data,
                  id: doc.id,
                  masaNo: order.masaNo,
                });
              }

              if (
                !latestOrder ||
                data.finishTime.toDate() > latestOrder.finishTime.toDate()
              ) {
                latestOrder = { ...data, id: doc.id, masaNo: order.masaNo };
              }
            }
          });
        }
      });

      setUpdatedMasalar(updates);
      setLatestOrder(latestOrder);

      if (newOrdersArray.length > 0) {
        const uniqueNewOrders = newOrdersArray.reduce((acc, current) => {
          const x = acc.find((item) => item.masaNo === current.masaNo);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);

        setNewOrders(uniqueNewOrders);
        const firstNewOrder = uniqueNewOrders[0];
        setSelectedMasa(firstNewOrder.masaNo);
        setOrderDetails(updatedMasalar[firstNewOrder.masaNo]?.orders || []);
        setProcessing(
          updatedMasalar[firstNewOrder.masaNo]?.processing || false
        );
        setModalIsOpen(true);

        if (!soundPlayed) {
          playNotificationSound();
          setSoundPlayed(true);
        }
      } else {
        setSoundPlayed(false);
      }
    });

    const paidOrdersQuery = query(
      collection(db, "orders"),
      where("finishTime", ">=", todayStart),
      where("finishTime", "<=", todayEnd),
      where("pay", "==", true),
      orderBy("finishTime", "asc")
    );

    const paidOrdersUnsubscribe = onSnapshot(paidOrdersQuery, (snapshot) => {
      const paidOrdersMap = new Map();

      snapshot.forEach((doc) => {
        const data = doc.data();
        if (data.orders && Array.isArray(data.orders)) {
          data.orders.forEach((order) => {
            if (order.masaNo) {
              if (!paidOrdersMap.has(order.masaNo)) {
                paidOrdersMap.set(order.masaNo, {
                  ...data,
                  id: doc.id,
                  masaNo: order.masaNo,
                });
              }
            }
          });
        }
      });

      setPaidOrders(Array.from(paidOrdersMap.values()));
    });

    return () => {
      unsubscribe();
      paidOrdersUnsubscribe();
    };
  }, [updatedMasalar, soundPlayed]);

  const openModal = (masa) => {
    if (updatedMasalar[masa]) {
      setSelectedMasa(masa);
      const orders = updatedMasalar[masa]?.orders || [];
      setOrderDetails(orders);
      setProcessing(updatedMasalar[masa]?.processing || false);
      setSelectedOrderId(updatedMasalar[masa]?.id || null);
      setPay(updatedMasalar[masa]?.pay || null);
      // Toplam ödeme tutarını hesapla
      const total = orders.reduce(
        (acc, order) => acc + order.price * order.quantity,
        0
      );
      setTotalAmount(total); // Toplam tutarı state'e kaydet

      setModalIsOpen(true);
    } else {
      console.error(`Masa bilgisi bulunamadı: ${masa}`);
    }
  };

  const openOrderModal = (orderDoc) => {
    // Eğer orderDoc doğrudan veri nesnesiyse, .data() kullanmanıza gerek yok
    const orderData = orderDoc.data ? orderDoc.data() : orderDoc;
    const documentId = orderDoc.id || orderData.id;

    setSelectedMasa(orderData.masaNo);
    setOrderDetails(orderData.orders || []);
    setProcessing(orderData.processing || false);
    setSelectedOrderId(documentId);
    setModalIsOpen(true);
    setPay(orderData.pay || null);

    // Toplam ödeme tutarını hesapla
    const total = orderData.orders.reduce((acc, order) => {
      const orderTotal = order.price * order.quantity; // Her sipariş için fiyat * miktar
      return acc + orderTotal;
    }, 0);

    setTotalAmount(total); // Toplam tutarı state'e kaydet
  };

  const handleProcessOrder = async () => {
    if (selectedMasa) {
      const masaOrder = updatedMasalar[selectedMasa];
      if (masaOrder) {
        try {
          const orderDocRef = doc(db, "orders", masaOrder.id);
          await updateDoc(orderDocRef, { processing: true });
          // Siparişlerin içindeki processingItem'ları güncelle
          const updatedOrders = masaOrder.orders.map((order) => ({
            ...order,
            processingItem: true,
          }));
          await updateDoc(orderDocRef, { orders: updatedOrders });
          closeModal();
        } catch (error) {
          console.error("Sipariş işlenirken bir hata oluştu:", error);
        }
      }
    }
  };

  const handlePayment = async () => {
    if (selectedOrderId) {
      try {
        const orderDocRef = doc(db, "orders", selectedOrderId);
        await updateDoc(orderDocRef, { pay: true });
        await hesaplaVeKaydetCiro();
        closeModal();
      } catch (error) {
        console.error("Ödeme güncellenirken bir hata oluştu:", error);
      }
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedMasa(null);
    setOrderDetails([]);
    setSelectedOrderId(null);
  };

  const handleDeleteOrder = async () => {
    if (selectedMasa) {
      const masaOrder = updatedMasalar[selectedMasa];
      if (masaOrder) {
        try {
          const orderDocRef = doc(db, "orders", masaOrder.id);
          await deleteDoc(orderDocRef);
          closeModal();
        } catch (error) {
          console.error("Sipariş silinirken bir hata oluştu:", error);
        }
      }
    }
  };

  const handleEditOrder = (order) => {
    setEditableOrder(order); // Düzenlenebilir siparişi ayarla
    setEditModalIsOpen(true); // Düzenleme modalini aç
  };

  const saveEditedOrder = async () => {
    try {
      if (editableOrder && selectedOrderId) {
        const orderDocRef = doc(db, "orders", selectedOrderId);

        const updatedOrders = orderDetails.map((order) =>
          order.orijinalDil === editableOrder.orijinalDil
            ? editableOrder
            : order
        );
        console.log(editableOrder.orijinalDil);

        await updateDoc(orderDocRef, { orders: updatedOrders });

        // Anlık güncellemeyi sağlamak için state güncellemesi
        setOrderDetails(updatedOrders);

        setEditModalIsOpen(false);
      } else {
        console.error("Editable order or selected order ID is missing.");
      }
    } catch (error) {
      console.error("Error saving edited order:", error);
    }
  };

  const closeEditModal = () => {
    setEditModalIsOpen(false); // Düzenleme modalini kapat
  };
  const saveNewOrder = async () => {
    try {
      if (selectedOrderId) {
        const orderDocRef = doc(db, "orders", selectedOrderId);

        // Mevcut siparişleri al
        const orderSnapshot = await getDoc(orderDocRef);
        const currentOrders = orderSnapshot.data()?.orders || [];

        // Yeni siparişi mevcut siparişler listesine ekle
        const updatedOrders = [...currentOrders, newOrder];

        await updateDoc(orderDocRef, { orders: updatedOrders });

        // Anlık güncellemeyi sağlamak için state güncellemesi
        setOrderDetails(updatedOrders);
        setNewOrder({
          orijinalDil: "",
          price: "",
          quantity: "",
          note: "",
          category: "",
        }); // Formu temizle
        setNewOrderModalIsOpen(false);
      } else {
        console.error("Selected order ID is missing.");
      }
    } catch (error) {
      console.error("Error saving new order:", error);
    }
  };

  const openNewOrderModal = () => {
    setNewOrderModalIsOpen(true);
  };

  const closeNewOrderModal = () => {
    setNewOrderModalIsOpen(false);
    setNewOrder({ name: "", price: "", quantity: "", note: "" }); // Formu temizle
  };
  const handleMenuDelete = async () => {
    try {
      const orderDocRef = doc(db, "orders", selectedOrderId); // Siparişin bulunduğu dokümanın referansı
      const orderDoc = await getDoc(orderDocRef); // Dokümanı al

      if (orderDoc.exists()) {
        const currentOrders = orderDoc.data().orders || []; // Mevcut siparişleri al
        const updatedOrders = currentOrders.filter(
          (order) => order.orijinalDil !== editableOrder.orijinalDil
        ); // Eşleşen siparişi çıkar

        // Dokümanı güncelle
        await updateDoc(orderDocRef, { orders: updatedOrders });

        // Anlık güncellemeyi sağlamak için state güncellemesi
        setOrderDetails((prevOrders) =>
          prevOrders.filter(
            (order) => order.orijinalDil !== editableOrder.orijinalDil
          )
        );

        closeEditModal(); // Modalı kapat
      } else {
        console.error("Sipariş dokümanı bulunamadı.");
      }
    } catch (error) {
      console.error("Sipariş silinirken hata oluştu:", error);
    }
  };
  const handleOnClick = React.useCallback(() => {
    printFn();
  }, [printFn]);
  return (
    <div>
      <div className="masa-siparis-container-admin">
        <h1 style={{ color: "white" }}>SİPARİŞLER</h1>
        <div className="masa-container">
          {masalar.map((masa) => (
            <div
              key={masa}
              className="masa"
              style={{
                backgroundColor: updatedMasalar[masa] ? "#16c216" : "white",
              }}
              onClick={() => openModal(masa)}
            >
              {masa}
            </div>
          ))}
        </div>
      </div>

      <div className="latest-paid">
        <div className="latest-order-container">
          <h2>Son Gelen Sipariş</h2>
          {latestOrder ? (
            <div
              className="order-item"
              onClick={() => openOrderModal(latestOrder)}
            >
              <p>
                <strong>Masa Numarası:</strong> {latestOrder.masaNo}
              </p>
              <p>
                <strong>Sipariş Tarihi:</strong>{" "}
                {latestOrder.finishTime.toDate().toLocaleString()}
              </p>
            </div>
          ) : (
            <p>Bugün için herhangi bir sipariş bulunmamaktadır.</p>
          )}
        </div>

        <div className="paid-orders-container">
          <div>
            <h2>Ödemesi Alınan Siparişler</h2>
          </div>
          {paidOrders.length > 0 ? (
            paidOrders.map((order) => (
              <div
                key={order.id}
                className="order-item"
                onClick={() => openOrderModal(order)}
              >
                <p>
                  <strong>Masa Numarası:</strong> {order.masaNo}
                </p>
                <p>
                  <strong>Sipariş Tarihi:</strong>{" "}
                  {order.finishTime.toDate().toLocaleString()}
                </p>
              </div>
            ))
          ) : (
            <p>Ödemesi alınan sipariş bulunmamaktadır.</p>
          )}
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Order Details"
        className="modal"
        overlayClassName="modal-overlay"
      >
        <div ref={componentRef}>
        <div className="print-header">
          <h2>Ergün Balık</h2>
          <p>Sarısu Mah. 2600 Sok. No:5</p>
          <p>0543 490 57 79</p>
          <h2>{selectedMasa}</h2>
          <hr />
        </div>
          <h2 className="selectedMasa">{selectedMasa}</h2>
          <div className="order-details">
          <div className="print-titles">
           <span className="print-title1"><strong>Ürün Adı:</strong></span>
        <span className="print-title2"><strong>Miktar:</strong></span>
        <span className="print-title3"><strong>Toplam Fiyat:</strong></span>
      </div>
            {processing ? (
              orderDetails.length > 0 ? (
                orderDetails.map((order, index) => (
                  <div key={index} className="order-item">
                   <p className="product-name">
                      <span className="order-detail-span"><strong>Ürün Adı:</strong></span> {order.orijinalDil}
                    </p>
                    <p className="order-category">
                    <span className="order-detail-span"><strong>Kategori:</strong></span> {order.category}
                    </p>
                    <p>
                    <span className="order-detail-span"><strong>Miktar:</strong></span> {order.quantity}
                    </p>
                    <p className="product-price">
                    <span className="order-detail-span"><strong>Toplam Fiyat:</strong></span> {order.totalPrice} TL
                    </p>
                    
                    <p className="order-note">
                    <span className="order-detail-span"><strong>Not:</strong></span> {order.note}
                    </p>
                    <button
                      className="edit-button" // Özel sınıf ekledik
                      style={{ backgroundColor: "#0056b3" }}
                      onClick={() => handleEditOrder(order)}
                    >
                      Düzenle
                    </button>
                  </div>
                ))
              ) : (
                <p>Bu masa için sipariş bulunmamaktadır.</p>
              )
            ) : orderDetails.length > 0 ? (
              orderDetails
                .filter((order) => !order.processingItem)
                .map((order, index) => (
                  <div key={index} className="order-item">
                   <p className="product-name">
                      <span className="order-detail-span"><strong>Ürün Adı:</strong></span> {order.orijinalDil}
                    </p>
                    <p className="order-category">
                    <span className="order-detail-span"><strong>Kategori:</strong></span> {order.category}
                    </p>
                    <p>
                    <span className="order-detail-span"><strong>Miktar:</strong></span> {order.quantity}
                    </p>
                    <p className="product-price">
                    <span className="order-detail-span"><strong>Toplam Fiyat:</strong></span> {order.totalPrice} TL
                    </p>
                    
                    <p className="order-note">
                    <span className="order-detail-span"><strong>Not:</strong></span> {order.note}
                    </p>
                  </div>
                ))
            ) : (
              <p>Bu masa için sipariş bulunmamaktadır.</p>
            )}
          </div>
          {/* Toplam ödeme ve masa bilgisi burada görünecek */}
          <div className="total-amount">
            <h3>Toplam Ödeme: {totalAmount} TL</h3> {/* Toplam ödeme bilgisi */}
            <p>
              {updatedMasalar[selectedMasa]?.garsonName
                ? `${updatedMasalar[selectedMasa].garsonName} ${updatedMasalar[selectedMasa].garsonSurname} tarafından verildi`
                : "Müşteri tarafından verildi"}
            </p>
            <p >
              {updatedMasalar[selectedMasa]?.note
                ? `Not: ${updatedMasalar[selectedMasa].note}`
                : ""}
            </p>
          </div>
          <div className="footer">
          <h2>Afiyet Olsun</h2>
          <hr />
        </div>
          {!processing ? (
            <div className="siparisiAl-kapat-buttons">
              <button
                style={{ backgroundColor: "#16c216" }}
                onClick={handleProcessOrder}
              >
                Siparişi Al
              </button>
              <button onClick={handleOnClick}>Siparişi Yazdır</button>
            </div>
          ) : pay ? (
            <>
              <button
                style={{ backgroundColor: "#0056b3" }}
                onClick={openNewOrderModal}
              >
                Yeni Sipariş Ekle
              </button>
              <button
                style={{ backgroundColor: "red" }}
                onClick={() =>
                  handleDeleteOrder(updatedMasalar[selectedMasa]?.id)
                }
              >
                Siparişi Sil
              </button>
            </>
          ) : (
            <>
              {!pay && (
                <button
                  style={{ backgroundColor: "#16c216" }}
                  onClick={handlePayment}
                >
                  Ödeme Al
                </button>
              )}
              <button
                style={{ backgroundColor: "#0056b3" }}
                onClick={openNewOrderModal}
              >
                Yeni Sipariş Ekle
              </button>
              <button onClick={handleOnClick}>Adisyon Çıkart</button>
              <button
                style={{ backgroundColor: "red" }}
                onClick={() =>
                  handleDeleteOrder(updatedMasalar[selectedMasa]?.id)
                }
              >
                Siparişi Sil
              </button>
            </>
          )}
        </div>
      </Modal>

      <Modal
        isOpen={editModalIsOpen}
        onRequestClose={closeEditModal}
        contentLabel="Edit Order"
        className="modal"
        overlayClassName="modal-overlay"
      >
        {editableOrder && (
          <div className="edit-order-modal">
            <h2>Siparişi Düzenle: {editableOrder.orijinalDil}</h2>

            <label>İsim:</label>
            <input
              type="text"
              value={editableOrder.orijinalDil}
              onChange={(e) =>
                setEditableOrder({ ...editableOrder, name: e.target.value })
              }
              disabled // İsim girişi kapatıldı
            />
            <label>Fiyat:</label>
            <input
              type="number"
              value={editableOrder.price}
              onChange={(e) =>
                setEditableOrder({ ...editableOrder, price: e.target.value })
              }
              disabled // Fiyat girişi kapatıldı
            />
            <label>Miktar:</label>
            <input
              type="number"
              value={editableOrder.quantity}
              onChange={(e) =>
                setEditableOrder({ ...editableOrder, quantity: e.target.value })
              }
            />
            <label>Not:</label>
            <input
              type="text"
              value={editableOrder.note}
              onChange={(e) =>
                setEditableOrder({ ...editableOrder, note: e.target.value })
              }
            />

            <button
              style={{ backgroundColor: "#0056b3" }}
              onClick={saveEditedOrder}
            >
              Kaydet
            </button>
            <button
              style={{ backgroundColor: "red" }}
              onClick={() => handleMenuDelete()} // Siparişi sil
            >
              Siparişi Sil
            </button>
          </div>
        )}
      </Modal>

      <Modal
  isOpen={newOrderModalIsOpen}
  onRequestClose={closeNewOrderModal}
  contentLabel="Yeni Sipariş Oluştur"
  className="modal"
  overlayClassName="modal-overlay"
>
  <h2>Yeni Sipariş Oluştur</h2>

  <label>Ürün Adı:</label>
  <input
    type="text"
    onChange={handleMenuSearch} // Arama işlemi
    placeholder="Menü ismi yazın..."
  />

  <select
    value={selectedMenu ? selectedMenu.orijinalDil : ""}
    onChange={(e) => {
      const selected = menuItems.find(
        (menu) => menu.orijinalDil === e.target.value
      );
      setSelectedMenu(selected);
      const newPrice = selected.price;
      const newTotalPrice = newPrice * newOrder.quantity; // Total price hesapla
      setNewOrder({
        ...newOrder,
        orijinalDil: selected.orijinalDil,
        price: newPrice,
        totalPrice: newTotalPrice, // Total price'ı güncelle
        category: selected.category, // Category'yi de ekliyoruz
      });
    }}
  >
    <option value="">Menü Seçin</option>
    {filteredMenuItems.map((menu) => (
      <option key={menu.id} value={menu.orijinalDil}>
        {menu.orijinalDil}
      </option>
    ))}
  </select>

  <label>Fiyat:</label>
  <input
    type="number"
    value={newOrder.price}
    onChange={(e) => setNewOrder({ ...newOrder, price: e.target.value })}
    disabled // Fiyat manuel değiştirilmesin
  />

  <label>Miktar:</label>
  <input
    type="number"
    value={newOrder.quantity}
    onChange={(e) => {
      const newQuantity = e.target.value;
      const newTotalPrice = newOrder.price * newQuantity; // Total price hesapla
      setNewOrder({ ...newOrder, quantity: newQuantity, totalPrice: newTotalPrice });
    }}
  />

  <label>Not:</label>
  <input
    type="text"
    value={newOrder.note}
    onChange={(e) => setNewOrder({ ...newOrder, note: e.target.value })}
  />

  <label>Toplam Fiyat:</label>
  <input
    type="number"
    value={newOrder.totalPrice} // Toplam fiyat göster
    disabled // Manuel değiştirilemesin
  />

  <button style={{ backgroundColor: "#0056b3" }} onClick={saveNewOrder}>
    Kaydet
  </button>
  <button style={{ backgroundColor: "red" }} onClick={closeNewOrderModal}>
    Kapat
  </button>
</Modal>

    </div>
  );
};

export default MasaSiparis;
