import React, { useState, useEffect } from "react";
import { collection, getDocs, query, where,Timestamp  } from "firebase/firestore";
import { db } from "../firebase";
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid, PieChart, Pie, Cell,BarChart,Bar } from "recharts";
import { format, startOfMonth,endOfMonth, isWithinInterval } from "date-fns";
import "./../css/Ciro.css";

const fetchOrders = async (start, end) => {
  try {

    const ordersCollection = collection(db, "orders");
    const ordersSnapshot = await getDocs(ordersCollection);
    const orders = ordersSnapshot.docs
      .map((doc) => {
        const orderData = doc.data().orders || [];
        const finishTime = doc.data().finishTime;
        return orderData.map((order) => ({
          ...order,
          finishTime: finishTime
            ? finishTime instanceof Timestamp
              ? finishTime.toDate()
              : new Date(finishTime)
            : null,
        }));
      })
      .flat();

    return orders.filter((order) => {
      const createdAtTimestamp = order.createdAt;
      const createdAt =
        createdAtTimestamp instanceof Timestamp
          ? createdAtTimestamp.toDate()
          : new Date(createdAtTimestamp);

      if (isNaN(createdAt.getTime())) {
        console.warn(`Geçersiz tarih: ${createdAtTimestamp}`);
        return false;
      }

      return isWithinInterval(createdAt, { start, end });
    });
  } catch (error) {
    console.error("Siparişleri alırken hata oluştu:", error);
    return [];
  }
};

const categorizeOrders = (orders) => {
  const categories = {};

  orders.forEach(item => {
    if (item && item.category && item.quantity && item.price && item.name) {
      if (!categories[item.category]) {
        categories[item.category] = { quantity: 0, total: 0, items: {} };
      }
      categories[item.category].quantity += item.quantity;
      categories[item.category].total += item.quantity * item.price;
      
      if (!categories[item.category].items[item.name]) {
        categories[item.category].items[item.name] = { quantity: 0, total: 0 };
      }
      categories[item.category].items[item.name].quantity += item.quantity;
      categories[item.category].items[item.name].total += item.quantity * item.price;
    } else {
      console.warn("Geçersiz sipariş verisi:", item);
    }
  });

  return categories;
};

const prepareChartData = (categories) => {
  return Object.keys(categories).map(key => ({
    label: key,
    value: categories[key].total,
    quantity: categories[key].quantity
  }));
};
const findTopSellingItem = (orders, category) => {
  const categoryMappings = {
    Yiyecek: ["Ekmek Arası Balık", "Tavada Balık", "Izgara Balık", "Meze", "Makarna", "Et", "Salata", "Suşi", "Çorba", "Ara Sıcak"],
    Alkol: ["Rakı", "Şarap", "Bira"],
    İçecek: ["İçecek"]
  };

  const filteredOrders = category === "All" 
    ? orders 
    : orders.filter(order => categoryMappings[category].includes(order.category));
  
  const categorizedData = categorizeOrders(filteredOrders);

  let topItem = { name: "", quantity: 0, total: 0 };

  Object.keys(categorizedData).forEach(cat => {
    const items = categorizedData[cat].items;
    Object.keys(items).forEach(itemName => {
      if (items[itemName].total > topItem.total) {
        topItem = { name: itemName, ...items[itemName], category: cat };
      }
    });
  });

  return topItem;
};
const calculateCustomerDensity = (orders) => {
  const density = Array(24).fill(0); // 24 saat için bir dizi oluştur
  const seenEntries = new Set(); // Gün, saat ve masa kombinasyonlarını takip etmek için bir Set oluştur

  orders.forEach((order) => {
    const finishTime = order.finishTime; // order içindeki finishTime'ı al
    const tableNumber = order.masaNo; // Masa numarasını al

    if (finishTime && tableNumber) {
      let finishDate;

      // Eğer finishTime bir Firestore Timestamp ise, Date nesnesine çevir
      if (finishTime instanceof Timestamp) {
        finishDate = finishTime.toDate();
      } else if (finishTime instanceof Date) {
        finishDate = finishTime; // Zaten Date nesnesi ise
      } else {
        // Eğer finishTime bir string ise, bunu Date nesnesine çevir
        finishDate = new Date(finishTime);
      }

      if (isNaN(finishDate.getTime())) {
        console.warn(`Geçersiz tarih: ${finishTime}`);
        return; // Geçersiz tarih varsa işleme devam etme
      }

      // Gün ve saat kombinasyonunu oluştur
      const dateKey = `${finishDate.toDateString()}-${finishDate.getHours()}-${tableNumber}`;

      // Eğer bu gün, saat ve masa kombinasyonu daha önce görülmemişse sayımı yap
      if (!seenEntries.has(dateKey)) {
        seenEntries.add(dateKey);
        const hour = finishDate.getHours(); // Sadece saat kısmını al
        density[hour]++; // Bu saatteki müşteri sayısını arttır
      }
    }
  });

  return density.map((count, hour) => ({
    hour,
    customers: count, // Her saat için toplam müşteri sayısını döndür
  }));
};

const AyCiro = () => {
  const [monthlyCiros, setMonthlyCiros] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [totalCiro, setTotalCiro] = useState(0);
  const [topSellingItem, setTopSellingItem] = useState({});
  const [selectedMainCategory, setSelectedMainCategory] = useState("All");
  const [customerDensityData, setCustomerDensityData] = useState([]);
  useEffect(() => {
    const fetchMonthlyData = async () => {
      try {
        const monthStart = format(startOfMonth(new Date()), 'yyyy-MM-dd');
        const monthEnd = format(endOfMonth(new Date()), 'yyyy-MM-dd');
        const monthStartDate = startOfMonth(monthStart);
        const monthEndDate = endOfMonth(monthEnd);
        // Fetch monthly ciro data
        const monthlyQuery = query(
          collection(db, "ciro"),
          where("__name__", ">=", monthStart),
          where("__name__", "<=", monthEnd)
        );
        const monthlyQuerySnapshot = await getDocs(monthlyQuery);
        const monthlyData = [];
        let monthlyTotal = 0;
        monthlyQuerySnapshot.forEach((doc) => {
          const data = doc.data().totalCiro;
          monthlyData.push({ date: doc.id, totalCiro: data });
          monthlyTotal += data;
        });
        setMonthlyCiros(monthlyData);
        setTotalCiro(monthlyTotal);

        // Fetch and categorize orders
        const orders = await fetchOrders(monthStartDate,monthEndDate);
        const categoryMappings = {
          Yiyecek: ["Ekmek Arası Balık", "Tavada Balık", "Izgara Balık", "Meze", "Makarna", "Et", "Salata", "Suşi", "Çorba", "Ara Sıcak"],
          Alkol: ["Rakı", "Şarap", "Bira"],
          İçecek: ["İçecek"]
        };
  
        // Seçilen kategoriye göre siparişleri filtrele
        const filteredOrders = selectedMainCategory === "All"
          ? orders
          : orders.filter(order => categoryMappings[selectedMainCategory].includes(order.category));
  
        const categorizedData = categorizeOrders(filteredOrders);
        setCategoryData(prepareChartData(categorizedData));
  
        // Seçilen kategoriye göre en çok satan ürünü belirle
        const topItem = findTopSellingItem(orders, selectedMainCategory);
        setTopSellingItem(topItem);

        const densityData = calculateCustomerDensity(orders);
        setCustomerDensityData(densityData);
  
      } catch (error) {
        console.error("Veri getirirken hata oluştu:", error);
      }
    };
    fetchMonthlyData();
  }, []);

  const COLORS = ["#8884d8", "#82ca9d", "#ff7300", "#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
  
  const handleMainCategoryChange = async (event) => {
    const mainCategory = event.target.value;
    setSelectedMainCategory(mainCategory);

    const monthStart = format(startOfMonth(new Date()), 'yyyy-MM-dd');
    const monthEnd = format(endOfMonth(new Date()), 'yyyy-MM-dd');
    const monthStartDate = startOfMonth(monthStart);
    const monthEndDate = endOfMonth(monthEnd);

    const orders = await fetchOrders(monthStartDate, monthEndDate);

    const categoryMappings = {
      Yiyecek: ["Ekmek Arası Balık", "Tavada Balık", "Izgara Balık", "Meze", "Makarna", "Et", "Salata", "Suşi", "Çorba", "Ara Sıcak"],
      Alkol: ["Rakı", "Şarap", "Bira"],
      İçecek: ["İçecek"]
    };

    const filteredOrders = mainCategory === "All"
      ? orders
      : orders.filter(order => categoryMappings[mainCategory].includes(order.category));

    const categorizedData = categorizeOrders(filteredOrders);
    setCategoryData(prepareChartData(categorizedData));

    // Update top-selling item based on the selected category
    const topItem = findTopSellingItem(filteredOrders, mainCategory);
    setTopSellingItem(topItem);
  };

  return (
    <div className="ciro-container-all-aylik">
      <div className="ciro-header-aylik">
        <h1>Aylık Ciro Yönetimi</h1>
        <div className="ciro-summary-aylik">
          <h2>Genel Ciro: {totalCiro} TL</h2>
        </div>
      </div>
      <div className="ciro-container-aylik">
        <div className="ciro-chart-aylik">
          <h2>Aylık Ciro</h2>
          <LineChart width={1000} height={500} data={monthlyCiros}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey="totalCiro" stroke={COLORS[0]} />
          </LineChart>
        </div>

        <div className="analiz-en-cok-aylik">
          <div className="ciro-chart-aylik">
            <h2>Kategori Analizi</h2>
            <div className="pie-chart-catergories" style={{ width: 700 }}>
            <PieChart width={700} height={700}>
                <Pie
                  data={categoryData}
                  dataKey="value"
                  cx="50%"
                  cy="40%"
                  outerRadius={150}
                  fill="#8884d8"
                  label={({ name, value }) => `${name}: ${value} TL`}
                >
                  {categoryData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                  layout="vertical"
                  verticalAlign="top"
                  align="right"
                  formatter={(value, entry, index) => (
                    <span
                      style={{
                        color: COLORS[index % COLORS.length],
                        fontWeight: "bold",
                      }}
                    >
                      {categoryData[index]?.label || value}
                    </span>
                  )}
                />
              </PieChart>
            </div>
          </div>

          <div className="en-cok-satan-kategori-sec-aylik">
            <div className="category-selector-aylik" style={{ marginLeft: 50 }}>
              <label htmlFor="category">Kategori Seçin:</label>
              <select
                id="category"
                value={selectedMainCategory}
                onChange={handleMainCategoryChange}
              >
                <option value="All">Tüm Kategoriler</option>
                <option value="Yiyecek">Yiyecek</option>
                <option value="Alkol">Alkol</option>
                <option value="İçecek">İçecek</option>
              </select>
            </div>
            <div
              className="top-selling-item-aylik"
              style={{ marginBottom: 50 }}
            >
              <h2>En Çok Satan Ürün</h2>
              <p>
                <strong>Kategori:</strong> {topSellingItem.category || "Yok"}
              </p>
              <p>
                <strong>Ürün:</strong> {topSellingItem.name || "Yok"}
              </p>
              <p>
                <strong>Satış Miktarı:</strong> {topSellingItem.quantity || 0}
              </p>
              <p>
                <strong>Ciroya Katkısı:</strong> {topSellingItem.total || 0} TL
              </p>
            </div>
          </div>
        </div>
        <div className="ciro-chart-aylik" style={{ width: 1000 }}>
          <h2>Yoğun Saatler</h2>
          <BarChart width={1000} height={300} data={customerDensityData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="hour"
              label={{
                value: "Saat",
                position: "insideBottomRight",
                offset: -5,
              }}
            />
            <YAxis
              label={{
                value: "Müşteri Sayısı",
                angle: -90,
                position: "insideLeft",
              }}
            />
            <Tooltip />
            <Legend />
            <Bar dataKey="customers" fill="#82ca9d" />
          </BarChart>
        </div>
      </div>
    </div>
  );
};

export default AyCiro;
