import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import { collection, getDocs,doc,updateDoc, addDoc } from "firebase/firestore"; // Firestore fonksiyonlarını import edin
import { db } from "../firebase"; // Firestore instance'ı import edin
import "./../css/GarsonSiparis.css"; // CSS dosyasını içe aktarın
import { getAuth, onAuthStateChanged } from "firebase/auth";

const GarsonSiparis = () => {
  const [orders, setOrders] = useState([]);
  const [masaNo, setMasaNo] = useState("");
  const [note, setNote] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [error, setError] = useState("");
  const [menus, setMenus] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [garsonInfo, setGarsonInfo] = useState({
    name: "",
    surname: "",
    email: "",
  });
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        // Kullanıcının email adresini al
        const garsonEmail = user.email;
        setGarsonInfo({ ...garsonInfo, email: garsonEmail });

        // Firestore'dan staff koleksiyonunu sorgula
        const staffCollection = collection(db, "staff");
        const staffSnapshot = await getDocs(staffCollection);
        staffSnapshot.forEach((doc) => {
          const staffData = doc.data();
          if (staffData.email === garsonEmail) {
            setGarsonInfo({
              name: staffData.name,
              surname: staffData.surname,
              email: garsonEmail,
            });
          }
        });
      } else {
        window.location = "/Garson"; // Kullanıcı oturum açmamışsa giriş sayfasına yönlendir
      }
    });

    return () => unsubscribe();
  }, [auth]);

  // Firestore'dan menüleri getir
  useEffect(() => {
    const fetchMenus = async () => {
      try {
        const menuCollection = collection(db, "menutr"); // Firestore koleksiyon adı 'menutr' olarak kalacak
        const menuSnapshot = await getDocs(menuCollection); // getDocs kullanımı
        const fetchedMenus = menuSnapshot.docs.map((doc) => doc.data());
        setMenus(fetchedMenus);
      } catch (error) {
        console.error("Error fetching menus:", error);
      }
    };

    fetchMenus();
  }, []);

  const calculateTotalPrice = (orders) => {
    return orders.reduce((total, order) => {
      return total + order.totalPrice;
    }, 0);
  };

  const addOrder = () => {
    if (!masaNo) {
      setError("Lütfen masa numarasını giriniz.");
      return;
    }
    if (selectedMenu === "") {
      setError("Lütfen bir menü seçiniz.");
      return;
    }
    const selectedMenuItem = menus.find((menu) => menu.name === selectedMenu);
    if (!selectedMenuItem) {
      setError("Seçilen menü bulunamadı.");
      return;
    }
    const newOrder = {
      id: uuidv4(),
      orijinalDil: selectedMenu,
      name: selectedMenu,
      price: selectedMenuItem.price,
      quantity: 1,
      totalPrice: selectedMenuItem.price * 1, // quantity * price
      masaNo: masaNo,
      createdAt: new Date(),
      category: selectedMenuItem.category || "Diğer", // Category alanını ekliyoruz
      processed: false,
      processingItem:false,
    };
    setOrders((prevOrders) => {
      const newOrders = [...prevOrders, newOrder];
      setTotalPrice(calculateTotalPrice(newOrders));
      return newOrders;
    });
    setSelectedMenu("");
    setError(""); // Hata mesajını temizle
  };

  const handleOrderChange = (id, field, value) => {
    setOrders((prevOrders) => {
      const newOrders = prevOrders.map((order) => {
        if (order.id === id) {
          const quantity =
            field === "quantity" && value > 0 ? value : order.quantity;
          return {
            ...order,
            [field]: value,
            totalPrice: order.price * quantity,
          };
        }
        return order;
      });
      setTotalPrice(calculateTotalPrice(newOrders));
      return newOrders;
    });
  };

  const deleteOrder = (id) => {
    setOrders((prevOrders) => {
      const newOrders = prevOrders.filter((order) => order.id !== id);
      setTotalPrice(calculateTotalPrice(newOrders));
      return newOrders;
    });
  };

  const handleSubmit = async () => {
    if (!masaNo) {
      setError("Masa numarası gereklidir.");
      return;
    }
  
    // Siparişlerde her biri için category alanını alıyoruz
    const ordersWithCategory = orders.map((order) => {
      const selectedMenuItem = menus.find((menu) => menu.name === order.name);
      return {
        ...order,
        orijinalDil: selectedMenuItem?.name || order.name,
        category: selectedMenuItem?.category || "Diğer", // Eğer category varsa ekliyoruz, yoksa "Diğer" olarak belirliyoruz
      };
    });
  
    // Mevcut siparişleri kontrol et
    const ordersCollection = collection(db, "orders");
    const querySnapshot = await getDocs(ordersCollection);
    const existingOrderDoc = querySnapshot.docs.find((doc) => {
      const order = doc.data();
      return order.masaNo === masaNo && !order.pay; // Masa numarası ve pay değeri kontrolü
    });
  
   // Firestore'da siparişi güncelle
if (existingOrderDoc) {
  const orderId = existingOrderDoc.id;
  const existingOrderData = existingOrderDoc.data();
  const updatedOrders = [...existingOrderData.orders];

  // Mevcut siparişleri güncelle
  ordersWithCategory.forEach((newOrder) => {
    const existingOrderItem = updatedOrders.find((order) => order.name === newOrder.name);
    if (existingOrderItem) {
      // Menü zaten mevcut, miktarı güncelle
      existingOrderItem.quantity += newOrder.quantity;
      existingOrderItem.totalPrice = existingOrderItem.price * existingOrderItem.quantity;
    } else {
      // Menü mevcut değil, yeni menü ekle
      updatedOrders.push(newOrder);
    }
  });

  // Toplam fiyatı güncelle
  const newTotalPrice = calculateTotalPrice(updatedOrders);

  // Firestore'da siparişi güncelle
  try {
    const orderRef = doc(db, "orders", orderId);
    await updateDoc(orderRef, {
      orders: updatedOrders,
      totalPrice: newTotalPrice,
      finishTime: new Date(), // Güncellenmiş finishTime ekleyelim
      processing: false, 
    });
    alert("Sipariş başarıyla güncellendi.");
    window.location.reload();
  } catch (error) {
    setError("Sipariş güncellenirken bir hata oluştu: " + error.message);
  }
}
 else {
      // Yeni sipariş oluştur
      const newOrder = {
        garsonName: garsonInfo.name,
        garsonSurname: garsonInfo.surname,
        garsonEmail: garsonInfo.email,
        masaNo,
        note,
        orders: ordersWithCategory,
        totalPrice,
        finishTime: new Date(),
        pay: false,
        processing: false,
      };
  
      try {
        await addDoc(collection(db, "orders"), newOrder); // Firestore'a yeni siparişi ekle
        setMasaNo("");
        setNote("");
        setOrders([]);
        setTotalPrice(0);
        setError("");
        setSelectedMenu("");
        alert("Sipariş başarıyla eklendi.");
      } catch (error) {
        setError("Sipariş eklenirken bir hata oluştu: " + error.message);
      }
    }
  };
  
  

  const filteredMenus = menus.filter((menu) =>
    menu.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="admin-container">
      <h2>Siparişler</h2>
      {orders.map((order) => (
        <div key={order.id} className="order">
          <div>
            <label>Sipariş İsmi:</label>
            <input type="text" value={order.name} disabled />
          </div>
          <div>
            <label>Adet:</label>
            <input
              type="number"
              value={order.quantity}
              onChange={(e) =>
                handleOrderChange(
                  order.id,
                  "quantity",
                  parseInt(e.target.value)
                )
              }
            />
          </div>
          <button className="sillButton" onClick={() => deleteOrder(order.id)}>
            Sil
          </button>
        </div>
      ))}
      <div>
        <label>Masa Numarası:</label>
        <select
          id="masaNo"
          value={masaNo}
          onChange={(e) => setMasaNo(e.target.value)}
          required
        >
          <option value="">Masa Seçiniz</option>
          <option value="İç Mekan-1">İç Mekan-1</option>
          <option value="İç Mekan-2">İç Mekan-2</option>
          <option value="İç Mekan-3">İç Mekan-3</option>
          <option value="İç Mekan-4">İç Mekan-4</option>
          <option value="İç Mekan-5">İç Mekan-5</option>
          <option value="İç Mekan-6">İç Mekan-6</option>
          <option value="İç Mekan-7">İç Mekan-7</option>
          <option value="İç Mekan-8">İç Mekan-8</option>
          <option value="Dış Mekan-1">Dış Mekan-1</option>
          <option value="Dış Mekan-2">Dış Mekan-2</option>
          <option value="Dış Mekan-3">Dış Mekan-3</option>
          <option value="Dış Mekan-4">Dış Mekan-4</option>
          <option value="Dış Mekan-5">Dış Mekan-5</option>
          <option value="Dış Mekan-6">Dış Mekan-6</option>
          <option value="Dış Mekan-7">Dış Mekan-7</option>
          <option value="Dış Mekan-8">Dış Mekan-8</option>
          <option value="Dış Mekan-9">Dış Mekan-9</option>
          <option value="Dış Mekan-10">Dış Mekan-10</option>
          <option value="Dış Mekan-11">Dış Mekan-11</option>
          <option value="Dış Mekan-12">Dış Mekan-12</option>
          <option value="Dış Mekan-13">Dış Mekan-13</option>
          <option value="Çardak-1">Çardak-1</option>
          <option value="Çardak-2">Çardak-2</option>
          <option value="Çardak-3">Çardak-3</option>
        </select>
      </div>
      <div>
        <label>Menü Ara:</label>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div>
        <label>Menü Seç:</label>
        <select
          value={selectedMenu}
          onChange={(e) => setSelectedMenu(e.target.value)}
        >
          <option value="">Menü seçiniz...</option>
          {filteredMenus.map((menu, index) => (
            <option key={index} value={menu.name}>
              {menu.name} - {menu.price} TL
            </option>
          ))}
        </select>
      </div>
      <button onClick={addOrder}>Sipariş Ekle</button>

      <div>
        <label>Not:</label>
        <textarea
          value={note}
          onChange={(e) => setNote(e.target.value)}
        ></textarea>
      </div>
      <div>
        <label>Toplam Fiyat:</label>
        <input type="text" value={totalPrice} disabled />
      </div>
      <button className="kaydetButton" onClick={handleSubmit}>
        Siparişi Kaydet
      </button>
      {error && <div className="error-message-garson">{error}</div>}
    </div>
  );
};

export default GarsonSiparis;
