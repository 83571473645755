import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage, ref } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyChJaRberg7fpmVLFCiSR8mLjEtJYWU_7E",
  authDomain: "restaurantqrproje.firebaseapp.com",
  databaseURL:
    "https://restaurantqrproje-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "restaurantqrproje",
  storageBucket: "restaurantqrproje.appspot.com",
  messagingSenderId: "841739091467",
  appId: "1:841739091467:web:1c611dd721c3a68fac110e",
  measurementId: "G-2G29P6VQR7",
};
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app); // Storage modülünü doğru şekilde alın

// Ardından ref yöntemini kullanarak bir referans oluşturun
const storageRef = ref(storage, "path/to/file");

export { auth, app, db, storage, storageRef };
