import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { db } from '../firebase';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, query, where, getDoc } from 'firebase/firestore';
import './../css/StockPage.css';

const StockPage = () => {
  const [stocks, setStocks] = useState([]);
  const [menus, setMenus] = useState([]);
  const [newStock, setNewStock] = useState({ name: '', category: '', quantity: 0 });
  const [isEditing, setIsEditing] = useState(false);
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    fetchMenus();
    fetchStocks();
  }, []);

  const fetchStocks = async () => {
    try {
      const stockCollection = collection(db, 'stok');
      const stockSnapshot = await getDocs(stockCollection);
      const stockList = stockSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setStocks(stockList);
      await adjustStockByOrders(stockList);
    } catch (error) {
      console.error('Stoklar getirilirken hata oluştu: ', error);
    }
  };

  const fetchMenus = async () => {
    try {
      const menuCollection = collection(db, 'menutr');
      const menuSnapshot = await getDocs(menuCollection);
      const menuList = menuSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setMenus(menuList);
    } catch (error) {
      console.error('Menüler getirilirken hata oluştu: ', error);
    }
  };

  const selectedOption = menus.find(menu => menu.name === newStock.name && menu.category === newStock.category);
  
  const handleProductSelect = (selectedOption) => {
    setNewStock({
      ...newStock,
      name: selectedOption.name,
      category: selectedOption.category
    });
  };

  const handleAddStock = async () => {
    // Boş veya geçersiz alan kontrolü
    if (!newStock.name || !newStock.category || newStock.quantity <= 0) {
      alert('Lütfen tüm alanları doğru şekilde doldurun.');
      return;
    }
    
    try {
      await addDoc(collection(db, 'stok'), newStock);
      setNewStock({ name: '', category: '', quantity: 0 });
      fetchStocks();
    } catch (error) {
      console.error('Stok eklenirken hata oluştu: ', error);
    }
  };
  

  const handleEditStock = async () => {
    try {
      const stockDoc = doc(db, 'stok', editingId);
      const stockSnapshot = await getDoc(stockDoc);

      if (!stockSnapshot.exists()) {
        console.error('Güncellenmek istenen stok dokümanı bulunamadı.');
        return;
      }

      await updateDoc(stockDoc, newStock);
      setIsEditing(false);
      setNewStock({ name: '', category: '', quantity: 0 });
      fetchStocks();
    } catch (error) {
      console.error('Stok güncellenirken hata oluştu: ', error);
    }
  };

  const handleDeleteStock = async (id) => {
    try {
      const stockDoc = doc(db, 'stok', id);
      await deleteDoc(stockDoc);
      // Silme işlemi sonrası listeyi güncelle
      setStocks(prevStocks => prevStocks.filter(stock => stock.id !== id));
      // Güncel stokları getir
      fetchStocks();
    } catch (error) {
      console.error('Stok silinirken hata oluştu: ', error);
    }
  };

  const adjustStockByOrders = async (stockList) => {
    try {
      const ordersCollection = collection(db, 'orders');
      const ordersQuery = query(ordersCollection, where('pay', '==', true));
      const ordersSnapshot = await getDocs(ordersQuery);
      const ordersList = ordersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const updatedStocks = stockList.map(stock => {
        let updatedQuantity = stock.quantity;

        ordersList.forEach(orderDoc => {
          if (!orderDoc.processed) {
            orderDoc.orders.forEach(orderItem => {
              if (stock.name === orderItem.name && stock.category === orderItem.category) {
                updatedQuantity -= orderItem.quantity;
              }
            });
          }
        });

        return { ...stock, quantity: updatedQuantity };
      });

      for (const stock of updatedStocks) {
        const stockDoc = doc(db, 'stok', stock.id);
        try {
          const stockSnapshot = await getDoc(stockDoc);
          if (stockSnapshot.exists()) {
            await updateDoc(stockDoc, { quantity: stock.quantity });
          }
        } catch (error) {
          console.error('Stok güncellenirken hata oluştu: ', error);
        }
      }

      for (const order of ordersList) {
        if (!order.processed) {
          const orderDoc = doc(db, 'orders', order.id);
          try {
            await updateDoc(orderDoc, { processed: true });
          } catch (error) {
            console.error('Sipariş güncellenirken hata oluştu: ', error);
          }
        }
      }
    } catch (error) {
      console.error('Siparişler ve stok güncellenirken hata oluştu: ', error);
    }
  };

  return (
    <div className="stock-page-container">
      <h1 className="stock-page-title">Stoktaki Ürünler</h1>
      <div className="stock-table-container">
        <table className="stock-table">
          <thead>
            <tr>
              <th>Ürün İsmi</th>
              <th>Kategori</th>
              <th>Adet</th>
              <th>İşlemler</th>
            </tr>
          </thead>
          <tbody>
            {stocks.map(stock => (
              <tr key={stock.id}>
                <td>{stock.name}</td>
                <td>{stock.category}</td>
                <td>{stock.quantity}</td>
                <td className="stock-action-buttons">
                  <button
                    className="stock-edit-button"
                    onClick={() => {
                      setIsEditing(true);
                      setEditingId(stock.id);
                      setNewStock({ name: stock.name, category: stock.category, quantity: stock.quantity });
                    }}>
                    Düzenle
                  </button>
                  <button
                    className="stock-delete-button"
                    onClick={() => handleDeleteStock(stock.id)}>
                    Sil
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <h2 className="stock-form-title">{isEditing ? 'Stok Düzenle' : 'Yeni Stok Ekle'}</h2>

      <div className="stock-select-container">
        <Select
          className="stock-select"
          options={menus.filter(menu => !stocks.some(stock => stock.name === menu.name && stock.category === menu.category))
                       .map(menu => ({ value: menu.id, label: menu.name, name: menu.name, category: menu.category }))}
          onChange={handleProductSelect}
          value={selectedOption ? { value: selectedOption.id, label: selectedOption.name } : null}
          placeholder="Ürün Seçin"
        />
      </div>

      <div className="stock-input-container">
        <input
          className="stock-input"
          type="text"
          placeholder="Kategori"
          value={newStock.category}
          onChange={(e) => setNewStock({ ...newStock, category: e.target.value })}
          readOnly
        />
      </div>

      <div className="stock-input-container">
        <input
          className="stock-input"
          type="number"
          placeholder="Adet"
          value={isNaN(newStock.quantity) ? '' : newStock.quantity}
          onChange={(e) => {
            const quantity = parseInt(e.target.value);
            setNewStock({ ...newStock, quantity: isNaN(quantity) ? 0 : quantity });
          }}
        />
      </div>

      <div className="stock-button-container">
        <button className="stock-button" onClick={isEditing ? handleEditStock : handleAddStock}>
          {isEditing ? 'Güncelle' : 'Ekle'}
        </button>
      </div>
    </div>
  );
};

export default StockPage;
