import React from "react";
import sepetEkle from "../images/cart.png";
import geriDon from "../images/left-chevron.png"; 
import "./../css/Navbar.css";

const NavigationBar = ({ dil, masa }) => {
  const handleSepetClick = () => {
    if (masa && dil) {
      window.location.href = `/sepet/${masa}/${dil}`;
    } else {
      console.error("Masa veya dil tanımlı değil!");
    }
  };

  const handleBackClick = () => {
    window.history.back();
  };

  return (
    <div className="navbar">
      <div className="navbar-back" onClick={handleBackClick}>
        <img src={geriDon} alt="Geri Dön" />
      </div>
      <div className="navbar-language"></div>
      <div className="navbar-sepet" onClick={handleSepetClick}>
        <img src={sepetEkle} alt="Sepete Ekle" />
      </div>
    </div>
  );
};

export default NavigationBar;
