import React, { useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../firebase";
import "./../css/Masa.css";
import image from "../images/logo.png";
import trFlag from "../images/turkey-flag.jpg";
import enFlag from "../images/england-flag.jpg";
import deFlag from "../images/deutsch-flag.jpg";
import ruFlag from "../images/russia-flag.jpg";
import arFlag from "../images/arabic-flag.jpg";

function Masa() {
  const [masa, setMasa] = useState("");
  const [error, setError] = useState(false);
  const [dil, setDil] = useState("tr");
  const [welcomeMessage, setWelcomeMessage] = useState(
    "Ergün Balık'a Hoşgeldiniz!"
  );
  const [tableSelectMessage, setTableSelectMessage] = useState(
    "Lütfen masa numaranızı seçin:"
  );
  const [tableMessage, setTableMessage] = useState("Masa Seçiniz");
  const [errorTableSelectMessage, setErrorTableSelectMessage] = useState(
    "Lütfen bir masa seçiniz."
  );

  const handleSubmit = async () => {
    if (masa === "") {
      setError(true); // Eğer masa numarası girilmediyse hata gösteriliyor
    } else {
      window.location = `/home/${masa}/${dil}`; // Doğru şekilde template literal kullanımı
    }
  };

  const handleLanguageChange = (lang) => {
    if (lang === "tr") {
      setWelcomeMessage("Ergün Balık'a Hoşgeldiniz!");
      setTableSelectMessage("Lütfen masa numaranızı seçin:");
      setTableMessage("Masa Seçiniz");
      setErrorTableSelectMessage("Lütfen bir masa seçiniz.");
    } else if (lang === "en") {
      setWelcomeMessage("Welcome to Ergün Fish!");
      setTableSelectMessage("Please select your table number:");
      setTableMessage("Select a Table");
      setErrorTableSelectMessage("Please choose a table.");
    } else if (lang === "de") {
      setWelcomeMessage("Willkommen bei Ergün Fisch!");
      setTableSelectMessage("Bitte wählen Sie Ihre Tischnummer aus:");
      setTableMessage("Wählen Sie eine Tabelle aus");
      setErrorTableSelectMessage("Bitte wählen Sie einen Tisch aus.");
    } else if (lang === "ru") {
      setWelcomeMessage("Добро пожаловать в ресторан 'Эргюн рыба'!");
      setTableSelectMessage("Пожалуйста, выберите номер вашего стола:");
      setTableMessage("Выберите стол");
      setErrorTableSelectMessage("Пожалуйста, выберите стол.");
    } else if (lang === "ar") {
      setWelcomeMessage("أهلاً بك في مطعم Ergün Fish!");
      setTableSelectMessage("الرجاء تحديد رقم الجدول الخاص بك:");
      setTableMessage("حدد جدولاً");
      setErrorTableSelectMessage("الرجاء اختيار الجدول.");
    }
    setDil(lang);
  };

  return (
    <div className="welcome-panel">
      <div>
        <img className="welcome-panel-logo" src={image} alt="Logo" />
      </div>
      <div className="language-select">
        <label htmlFor="language-title" style={{ color: "antiquewhite" }}>
          Choose the Language:
        </label>
        <div className="language-flag-buttons">
          <div
            className="flag-icon-container"
            onClick={() => handleLanguageChange("tr")}
          >
            <img src={trFlag} alt="Türkçe" className="flag-icon" />
          </div>
          <div
            className="flag-icon-container"
            onClick={() => handleLanguageChange("en")}
          >
            <img src={enFlag} alt="English" className="flag-icon" />
          </div>
          <div
            className="flag-icon-container"
            onClick={() => handleLanguageChange("de")}
          >
            <img src={deFlag} alt="Deutsch" className="flag-icon" />
          </div>
          <div
            className="flag-icon-container"
            onClick={() => handleLanguageChange("ru")}
          >
            <img src={ruFlag} alt="Russian" className="flag-icon" />
          </div>
          <div
            className="flag-icon-container"
            onClick={() => handleLanguageChange("ar")}
          >
            <img src={arFlag} alt="Arabic" className="flag-icon" />
          </div>
        </div>
      </div>
      <h1>{welcomeMessage}</h1>
      <p>{tableSelectMessage}</p>
      <select
        id="masa"
        value={masa}
        onChange={(e) => setMasa(e.target.value)}
        required
      >
        <option value="">{tableMessage}</option>
        <option value="İç Mekan-1">İç Mekan-1</option>
        <option value="İç Mekan-2">İç Mekan-2</option>
        <option value="İç Mekan-3">İç Mekan-3</option>
        <option value="İç Mekan-4">İç Mekan-4</option>
        <option value="İç Mekan-5">İç Mekan-5</option>
        <option value="İç Mekan-6">İç Mekan-6</option>
        <option value="İç Mekan-7">İç Mekan-7</option>
        <option value="İç Mekan-8">İç Mekan-8</option>
        <option value="Dış Mekan-1">Dış Mekan-1</option>
        <option value="Dış Mekan-2">Dış Mekan-2</option>
        <option value="Dış Mekan-3">Dış Mekan-3</option>
        <option value="Dış Mekan-4">Dış Mekan-4</option>
        <option value="Dış Mekan-5">Dış Mekan-5</option>
        <option value="Dış Mekan-6">Dış Mekan-6</option>
        <option value="Dış Mekan-7">Dış Mekan-7</option>
        <option value="Dış Mekan-8">Dış Mekan-8</option>
        <option value="Dış Mekan-9">Dış Mekan-9</option>
        <option value="Dış Mekan-10">Dış Mekan-10</option>
        <option value="Dış Mekan-11">Dış Mekan-11</option>
        <option value="Dış Mekan-12">Dış Mekan-12</option>
        <option value="Dış Mekan-13">Dış Mekan-13</option>
        <option value="Çardak-1">Çardak-1</option>
        <option value="Çardak-2">Çardak-2</option>
        <option value="Çardak-3">Çardak-3</option>
      </select>
      <button className="button-masa" onClick={handleSubmit}>
        OK
      </button>

      {error && <p className="masa-error-message">{errorTableSelectMessage}</p>}
    </div>
  );
}

export default Masa;
