import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  query,
  orderBy,
  limit,
  getDocs,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import sepet from "./../images/cart4.svg";
import styles from "./../css/BalikDetailPage.module.css";
import NavigationBar from "../components/Navbar";

const BalikDetailPage = () => {
  const { id, masa, dil } = useParams();
  const [menuItem, setMenuItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [note, setNote] = useState("");

  useEffect(() => {
    const fetchMenuItem = async () => {
      try {
        if (!dil) return;

        const docRef = doc(db, `menu${dil}`, id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setMenuItem({ id: docSnap.id, ...docSnap.data(), quantity: 0 });
        } else {
          console.log("No such document!");
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching menu item:", error);
        setLoading(false);
      }
    };

    fetchMenuItem();
  }, [id, dil]);

  const handleAddToCart = async () => {
    try {
      if (menuItem.quantity === 0) {
        toast.error("Lütfen adet giriniz.");
        return;
      }
      const totalPrice = parseInt(menuItem.quantity) * menuItem.price;

      const cartItem = {
        name: menuItem.name,
        orijinalDil: menuItem.orijinalDil,
        quantity: menuItem.quantity,
        price: menuItem.price,
        totalPrice: totalPrice,
        masaNo: masa,
        category: menuItem.category,
        createdAt: new Date(),
        imageUrl: menuItem.imageUrl,
        note: note, // Not ekleme
        processed: false,
        processingItem: false,
      };

      await addDoc(collection(db, "cart"), cartItem);
      let message = "";
      switch (dil) {
        case "en":
          message = `"${menuItem.name}" added to cart, ${menuItem.quantity} piece(s).`;
          break;
        case "ru":
          message = `"${menuItem.name}" добавлено в корзину, ${menuItem.quantity} штук(и).`;
          break;
        case "de":
          message = `"${menuItem.name}" zum Warenkorb hinzugefügt, ${menuItem.quantity} Stück.`;
          break;
        case "ar":
          message = `"${menuItem.name}" تمت إضافته إلى السلة، ${menuItem.quantity} قطعة.`;
          break;
        default:
          message = `"${menuItem.name}" sepete eklendi, ${menuItem.quantity} adet.`;
      }

      toast.success(message);
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value);
    setMenuItem((prevMenuItem) => ({
      ...prevMenuItem,
      quantity: newQuantity,
    }));
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <div className={styles.message}>Loading...</div>
      </div>
    );
  }

  if (!menuItem) {
    return <div>No item found</div>;
  }

  return (
    <div>
      <NavigationBar masa={masa} dil={dil} />
      <div className={styles.balikMenuContainer}>
        <ToastContainer /> {/* Toastify için container ekleniyor */}
        <h2 className={styles.heading}>{menuItem.name}</h2>
        <img
          src={menuItem.imageUrl}
          alt={menuItem.name}
          className={styles.menuImage}
        />
        <p className={styles.price}>{menuItem.price}TL</p>
        <p className={styles.text}>{menuItem.description}</p>
        <textarea
          placeholder="Sipariş Notu"
          rows="4"
          value={note}
          onChange={handleNoteChange}
          className={styles.noteInput}
        ></textarea>
        <div className={styles.siparisver}>
          <input
            type="number"
            min="0"
            value={menuItem.quantity}
            onChange={handleQuantityChange}
            className={styles.buttonInput}
          />
          <img
            className={styles.menuSepet}
            src={sepet}
            onClick={handleAddToCart}
            alt="Sepet"
          />
        </div>
      </div>
    </div>
  );
};

export default BalikDetailPage;
