import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  query,
  orderBy,
  getDocs,
  deleteDoc,
  doc,
  updateDoc,
  getDoc,
  where,
} from "firebase/firestore";
import { db, storage } from "../firebase";
import "./../css/AdminMenu.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const AdminMenu = () => {
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [recipeUrl, setRecipeUrl] = useState("");
  const [image, setImage] = useState(null);
  const [category, setCategory] = useState("");
  const [menus, setMenus] = useState([]);
  const [selectedLanguage, setSelectedLanguage] = useState("tr");
  const apiKey = "AIzaSyB1jS423Z_fbCCl-dHwnInUGk6YFUfcoxY";
  const auth = getAuth();
  const [priceMap, setPriceMap] = useState("");
  const [user, setUser] = useState(null);
  
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (!user) {
        window.location = "/admin";
      }
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const fetchMenus = async () => {
      try {
        const menuCollection = collection(db, `menu${selectedLanguage}`);
        const menuSnapshot = await getDocs(
          query(menuCollection, orderBy("name"))
        );
        const menuList = menuSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const priceMap = {};
        menuList.forEach((menu) => {
          
          priceMap[menu.id] = menu.price;
        });
        setMenus(menuList);
      } catch (error) {
        console.error("Error fetching menus: ", error);
      }
    };

    fetchMenus();
  }, [selectedLanguage]);

  const handleLanguageChange = (language) => {
    setSelectedLanguage(language);
  };
  const handlePriceChange = (e, menuId) => {
    const newPrice = e.target.value;
    if (newPrice >= 0) {
      setPriceMap((prevPriceMap) => ({
        ...prevPriceMap,
        [menuId]: newPrice,
      }));
    }
  };
  const handleSetPrice = (newPrice) => {
    if (newPrice >= 0) {
      setPrice(newPrice);
    }
  };
  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  const handleUpdatePrice = async (menuId) => {
    try {
      const menuRef = doc(db, `menu${selectedLanguage}`, menuId);
      await updateDoc(menuRef, {
        price: priceMap[menuId],
        createdAt: new Date(),
      });
      toast.success("Menü fiyatı başarıyla güncellendi!");
    } catch (error) {
      console.error("Error updating menu price:", error);
      toast.error("Menü fiyatı güncelleme başarısız oldu.");
    }
  };

  const handleDeleteMenu = async (menuId) => {
    try {
      const menuRef = doc(db, `menu${selectedLanguage}`, menuId);
      const menuSnapshot = await getDoc(menuRef);
      const orijinalDil = menuSnapshot.data().orijinalDil;

      const languages = ["tr", "de", "ru", "ar", "en"];
      const deletePromises = languages.map(async (lang) => {
        const langCollection = collection(db, `menu${lang}`);
        const q = query(
          langCollection,
          where("orijinalDil", "==", orijinalDil)
        );
        const querySnapshot = await getDocs(q);
        querySnapshot.forEach(async (doc) => {
          await deleteDoc(doc.ref);
        });
      });

      await Promise.all(deletePromises);

      const updatedMenus = menus.filter((menu) => menu.id !== menuId);
      setMenus(updatedMenus);
      toast.success("Menü başarıyla silindi!");
    } catch (error) {
      console.error("Menü silinirken hata: ", error);
      toast.error("Menü silme başarısız oldu.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const fileName = image.name;
      const storageRef = ref(storage, `menuimages/${fileName}`);
      await uploadBytes(storageRef, image);
      const imageUrl = await getDownloadURL(storageRef);

      const newMenuRef = await addDoc(
        collection(db, `menu${selectedLanguage}`),
        {
          name: name,
          price: price,
          description: description,
          recipeUrl: recipeUrl,
          imageUrl: imageUrl,
          category: category,
          orijinalDil: name,
          createdAt: new Date(),
        }
      );

      await translateAndSaveInDifferentLanguages(
        name,
        description,
        category,
        imageUrl
      );

      const newMenu = {
        id: newMenuRef.id,
        name,
        price,
        description,
        recipeUrl,
        imageUrl,
        category,
        createdAt: new Date(),
      };

      setMenus([...menus, newMenu]);
      toast.success("Menü başarıyla eklendi.");
    } catch (error) {
      console.error("Error adding menu:", error);
      toast.error("Menü ekleme başarısız oldu.");
    }
  };

  const translateAndSaveInDifferentLanguages = async (
    name,
    description,
    category,
    imageUrl
  ) => {
    const languages = ["de", "ru", "ar", "en"];
    const translatedItems = await Promise.all(
      languages.map(async (lang) => {
        try {
          const response = await axios.post(
            `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`,
            {
              q: [name, description, category],
              source: "tr",
              target: lang,
            }
          );
          const translatedData = response.data.data.translations;
          return {
            name: translatedData[0].translatedText,
            description: translatedData[1].translatedText,
            category: translatedData[2].translatedText,
            imageUrl: imageUrl,
            language: lang,
            orijinalDil: name,
          };
        } catch (error) {
          console.error("Translation Error:", error);
          return null;
        }
      })
    );

    translatedItems.forEach(async (item) => {
      if (item) {
        try {
          const langCollection = collection(db, `menu${item.language}`);
          await addDoc(langCollection, {
            name: item.name,
            price: price,
            description: item.description,
            recipeUrl: recipeUrl,
            imageUrl: imageUrl,
            category: category,
            orijinalDil: item.orijinalDil,
            createdAt: new Date(),
          });
          console.log(`Menu successfully saved in ${item.language} collection`);
        } catch (error) {
          console.error(
            `Error saving menu in ${item.language} collection:`,
            error
          );
        }
      }
    });
  };

  return (
    <div className="admin-menu-container">
      <ToastContainer />
      <h2>Yeni Yemek Ekle</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Yemek İsmi:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="price">Yemek Fiyatı:</label>
          <input
            type="number"
            id="price"
            value={price}
            onChange={(e) => handleSetPrice(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">İçerik:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="recipeUrl">Yemek Tarifi URL:</label>
          <input
            type="text"
            id="recipeUrl"
            value={recipeUrl}
            onChange={(e) => setRecipeUrl(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="category">Kategori:</label>
          <select
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
          >
            <option value="">Kategori Seçiniz</option>
            <option value="Ekmek Arası Balık">Ekmek Arası Balık</option>
            <option value="Tavada Balık">Tavada Balık</option>
            <option value="Izgara Balık">Izgara Balık</option>
            <option value="Meze">Meze</option>
            <option value="Makarna">Makarna</option>
            <option value="Et">Et</option>
            <option value="Salata">Salata</option>
            <option value="Suşi">Suşi</option>
            <option value="Çorba">Çorba</option>
            <option value="Ara Sıcak">Ara Sıcak</option>
            <option value="Rakı">Rakı</option>
            <option value="Şarap">Şarap</option>
            <option value="Bira">Bira</option>
            <option value="İçecek">İçecek</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="image">Yemek Resmi:</label>
          <input type="file" id="image" onChange={handleFileChange} required />
        </div>
        <button type="submit">Ekle</button>
      </form>
      <div className="language-buttons">
        <button onClick={() => handleLanguageChange("tr")}>TR</button>
        <button onClick={() => handleLanguageChange("de")}>DE</button>
        <button onClick={() => handleLanguageChange("ru")}>RU</button>
        <button onClick={() => handleLanguageChange("ar")}>AR</button>
        <button onClick={() => handleLanguageChange("en")}>EN</button>
      </div>

      <h2>Yemek Menüsü</h2>
      <ul>
        {menus.map((menu) => (
          <li key={menu.id}>
            <img src={menu.imageUrl} alt={menu.name} />
            <div>
              <h3>{menu.name}</h3>
              <p>{menu.description}</p>
              <p>Fiyat: {menu.price}</p>

              <div className="input-update-delete">
                <input
                  type="number"
                  value={priceMap[menu.id]}
                  onChange={(e) => handlePriceChange(e, menu.id)}
                />
                <div className="update-delete">
                  <button
                    className="update-price"
                    onClick={() => handleUpdatePrice(menu.id)}
                  >
                    Fiyatı Güncelle
                  </button>
                  <button
                    className="delete-button"
                    onClick={() => handleDeleteMenu(menu.id)}
                  >
                    Sil
                  </button>
                </div>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdminMenu;
