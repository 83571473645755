import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  deleteDoc,
  doc,
  updateDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./../css/Sepet.css";
import { subHours } from "date-fns";
import { serverTimestamp, writeBatch } from "firebase/firestore";
import axios from "axios";
import { startOfDay, endOfDay } from "date-fns";
import NavigationBar from "../components/Navbar";
import styles from "./../css/BalikDetailPage.module.css";
import { useParams } from "react-router-dom";
const SepetPage = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPrice, setTotalPrice] = useState(0);
  const [note, setNote] = useState(""); // Not alanı için state ekledik
  const lastHour = subHours(new Date(), 1);
  const apiKey = "AIzaSyB1jS423Z_fbCCl-dHwnInUGk6YFUfcoxY";
  const { masa, dil } = useParams();
  useEffect(() => {
    const fetchMenuItems = async () => {
      try {
        const now = new Date();
        const thirtyMinutesAgo = new Date(now.getTime() - 30 * 60 * 1000); // 30 dakika önce
        console.log(`30dk önce: ${thirtyMinutesAgo}`)
        // "masaNo"ya göre ve "createdAt" 30 dakikadan daha yeni olanları sorgula
        const q = query(
          collection(db, "cart"),
          where("masaNo", "==", masa),
          where("createdAt", ">=", thirtyMinutesAgo) // 30 dakikadan yeni olanları getir
        );

        const querySnapshot = await getDocs(q);
        const menuItemsData = [];
        let total = 0;

        // Sepet öğelerini ekrana göster ve toplam fiyatı hesapla
        querySnapshot.forEach((doc) => {
          const item = { id: doc.id, ...doc.data() };
          menuItemsData.push(item);
          total += item.totalPrice;
        });

        setMenuItems(menuItemsData);
        setTotalPrice(total);
        setLoading(false);

        // 30 dakikadan eski öğeleri silmek için bir başka sorgu yap
        const oldItemsQuery = query(
          collection(db, "cart"),
          where("masaNo", "==", masa),
          where("createdAt", "<", thirtyMinutesAgo) // 30 dakikadan eski olanları getir
        );

        const oldItemsSnapshot = await getDocs(oldItemsQuery);
        oldItemsSnapshot.forEach(async (doc) => {
          await deleteDoc(doc.ref); // 30 dakikadan eski olanları sil
        });

      } catch (error) {
        console.error("Error fetching menu items:", error);
        setLoading(false);
      }
    };

    fetchMenuItems();
  }, [masa]);
  const handleQuantityChange = (id, change) => {
    setMenuItems((prevMenuItems) => {
      const updatedMenuItems = prevMenuItems.map((item) => {
        if (item.id === id) {
          const newQuantity = Math.max(0, item.quantity + change);
          const newTotalPrice = newQuantity * item.price;
          return { ...item, quantity: newQuantity, totalPrice: newTotalPrice };
        }
        return item;
      });
      const newTotalPrice = updatedMenuItems.reduce(
        (total, item) => total + item.totalPrice,
        0
      );
      setTotalPrice(newTotalPrice);
      return updatedMenuItems;
    });
  };

  const handleDelete = async (id) => {
    try {
      await deleteDoc(doc(db, "cart", id));
      setMenuItems((prevMenuItems) => {
        const remainingMenuItems = prevMenuItems.filter(
          (item) => item.id !== id
        );
        const newTotalPrice = remainingMenuItems.reduce(
          (total, item) => total + item.totalPrice,
          0
        );
        setTotalPrice(newTotalPrice);
        return remainingMenuItems;
      });
    } catch (error) {
      console.error("Error deleting menu item:", error);
    }
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <div className={styles.message}>Loading...</div>
      </div>
    );
  }

  const handleFinishOrder = async () => {
    try {
      const todayStart = startOfDay(new Date());
      const todayEnd = endOfDay(new Date());
      const q = query(collection(db, "cart"), where("masaNo", "==", masa));
      const querySnapshot = await getDocs(q);
      const batch = writeBatch(db);

      // Mevcut siparişi kontrol et
      const existingOrderQuery = query(
        collection(db, "orders"),
        where("masaNo", "==", masa),
        where("finishTime", ">=", todayStart),
      where("finishTime", "<=", todayEnd),
        where("pay", "==", false)
      );
      const existingOrderSnapshot = await getDocs(existingOrderQuery);

      if (!existingOrderSnapshot.empty) {
        // Mevcut sipariş bulunursa, bu siparişi güncelle
        const existingOrderDoc = existingOrderSnapshot.docs[0];
        const existingOrderData = existingOrderDoc.data();
        const existingOrdersData = existingOrderData.orders || [];

        const newOrdersData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            name: data.orijinalDil, 
            ...data,
            category: data.category,
          };
        });

        // Yeni siparişleri mevcut siparişe ekle
        const updatedOrdersData = [...existingOrdersData, ...newOrdersData];

        await updateDoc(existingOrderDoc.ref, {
          orders: updatedOrdersData,
          finishTime: serverTimestamp(),
          note: note,
          processing: false,
        });
        let message = "";
        if (dil === "en") {
          message = `Your order has been received successfully.`;
        } else if (dil === "ru") {
          message = `Ваш заказ успешно получен.`;
        } else if (dil === "de") {
          message = `Ihre Bestellung wurde erfolgreich empfangen.`;
        } else if (dil === "ar") {
          message = `لقد تم استلام طلبك بنجاح.`;
        } else {
          message = `Siparişiniz başarılı bir şekilde alındı.`;
        }

        toast.success(message);
      } else {
        // Mevcut sipariş bulunamazsa, yeni sipariş ekle
        const ordersData = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            ...data,
            name: data.orijinalDil, 
            category: data.category,
          };
        });

        let translatedNote = note;
        if (note) {
          try {
            const response = await axios.post(
              `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`,
              {
                q: [note],
                source: dil,
                target: "tr",
              }
            );
            translatedNote = response.data.data.translations[0].translatedText;
          } catch (error) {
            console.error("Translation Error:", error);
          }
        }

        await addDoc(collection(db, "orders"), {
          orders: ordersData,
          finishTime: serverTimestamp(),
          dil,
          note: translatedNote,
          pay: false,
          masaNo: masa, // Masa numarasını da ekliyoruz
          processing: false,
        });
        let message = "";
        if (dil === "en") {
          message = `Your order has been received successfully.`;
        } else if (dil === "ru") {
          message = `Ваш заказ успешно получен.`;
        } else if (dil === "de") {
          message = `Ihre Bestellung wurde erfolgreich empfangen.`;
        } else if (dil === "ar") {
          message = `لقد تم استلام طلبك بنجاح.`;
        } else {
          message = `Siparişiniz başarılı bir şekilde alındı.`;
        }

        toast.success(message);
      }

      // Sepeti temizle
      querySnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
      await batch.commit();

      setTimeout(() => {
        window.location = "/";
      }, 5000);
    } catch (error) {
      console.error("Error finishing order:", error);
    }
  };

  return (
    <div>
      <NavigationBar masa={masa} dil={dil} />
      <div className="container">
        <ToastContainer />
        <div className="sepet-container">
          <h1>
            {dil === "en"
              ? "CART"
              : dil === "ru"
              ? "КОРЗИНА"
              : dil === "de"
              ? "KORB"
              : dil === "ar"
              ? "سلة"
              : "SEPET"}
          </h1>
          <ul>
            {menuItems.map((menuItem) => (
              <li key={menuItem.id} className="siparis">
                <div className="yemekImg">
                  <img
                    src={menuItem.imageUrl}
                    alt={menuItem.name}
                    className="foto"
                  />
                </div>
                <div className="yemekisim">
                  <p>{menuItem.name} </p>
                </div>

                <div className="yemekquantity">
                  <p>
                    {dil === "en"
                      ? "Quantity:"
                      : dil === "ru"
                      ? "Число:"
                      : dil === "de"
                      ? "Nummer:"
                      : dil === "ar"
                      ? "رقم:"
                      : "Sayı:"}
                  </p>
                  <div className="artırmazaltma">
                    <div>
                      <button
                        onClick={() => handleQuantityChange(menuItem.id, -1)}
                      >
                        <p>-</p>
                      </button>
                    </div>
                    <div className="quantity">
                      <p>{menuItem.quantity} </p>
                    </div>
                    <div>
                      <button
                        onClick={() => handleQuantityChange(menuItem.id, 1)}
                      >
                        <p> +</p>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="yemektotalprice">
                  <p>
                    {dil === "en"
                      ? "Price:"
                      : dil === "ru"
                      ? "Цена:"
                      : dil === "de"
                      ? "Preis::"
                      : dil === "ar"
                      ? "سعر:"
                      : "Fiyat:"}
                  </p>
                  <p>{menuItem.totalPrice} </p>
                </div>
                <div className="silButton">
                  <button onClick={() => handleDelete(menuItem.id)}>Sil</button>
                </div>
              </li>
            ))}
          </ul>
        </div>
        <div className="toplam-fiyat-container">
          <div className="toplam-fiyat">
            <h2>
              {dil === "en"
                ? "Total price:"
                : dil === "ru"
                ? "Итоговая цена:"
                : dil === "de"
                ? "Gesamtpreis:"
                : dil === "ar"
                ? "السعر الاجمالي:"
                : "Toplam Fiyat:"}{" "}
              {totalPrice} TL
            </h2>
          </div>
          <div className="siparis-note">
            <textarea
              placeholder={
                dil === "en"
                  ? "Add a note to your order"
                  : dil === "ru"
                  ? "Добавить заметку к вашему заказу"
                  : dil === "de"
                  ? "Fügen Sie Ihrer Bestellung eine Notiz hinzu"
                  : dil === "ar"
                  ? "أضف ملاحظة إلى طلبك"
                  : "Siparişinize bir not ekleyin"
              }
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
          <div className="siparisi-bitir">
            <button onClick={handleFinishOrder}>
              {dil === "en"
                ? "Finish Order"
                : dil === "ru"
                ? "закончить заказ"
                : dil === "de"
                ? "Reihenfolge beenden"
                : dil === "ar"
                ? "إنهاء الطلب"
                : "Siparişi Bitir"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SepetPage;
