import React, { useState, useEffect } from "react";
import { collection, query, orderBy, deleteDoc, doc, onSnapshot,getDocs } from "firebase/firestore";
import { db } from "../firebase";
import Modal from "react-modal";
import "./../css/AdminSiparis.css";
import { differenceInMinutes } from "date-fns";

import { getAuth, onAuthStateChanged } from "firebase/auth";

const AdminSiparis = () => {
  const [siparisler, setSiparisler] = useState([]);
  const auth = getAuth();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (!user) {
        window.location = "/admin"; // Kullanıcı oturum açmamışsa giriş sayfasına yönlendir
      }
    });

    return () => unsubscribe();
  }, [auth]);

  useEffect(() => {
    const fetchSiparisler = async () => {
      try {
        const ordersQuerySnapshot = await getDocs(
          query(collection(db, "orders"), orderBy("finishTime", "desc"))
        );
        const siparisListesi = [];

        ordersQuerySnapshot.forEach((doc) => {
          const siparisData = {
            id: doc.id,
            garsonName: doc.data().garsonName || "",
            garsonSurname: doc.data().garsonSurname || "",
            orders: doc.data().orders,
            note: doc.data().note, // Notu buraya ekleyin
            finishTime: doc.data().finishTime.toDate(),
          };
          siparisListesi.push(siparisData);
        });

        setSiparisler(siparisListesi);
      } catch (error) {
        console.error("Error fetching orders:", error);
      }
    };
    fetchSiparisler();

    const unsubscribe = onSnapshot(
      query(collection(db, "orders"), orderBy("finishTime", "desc")),
      (snapshot) => {
        const updatedSiparisler = [];
        snapshot.forEach((doc) => {
          const siparisData = {
            id: doc.id,
            garsonName: doc.data().garsonName || "",
            garsonSurname: doc.data().garsonSurname || "",
            orders: doc.data().orders,
            note: doc.data().note, // Notu buraya ekleyin
            finishTime: doc.data().finishTime.toDate(),
          };
          updatedSiparisler.push(siparisData);
        });

        setSiparisler(updatedSiparisler);
        updatedSiparisler.forEach((masaSiparisi) => {
          
        });
      }
    );

    return () => unsubscribe();
  }, []);

  const calculateElapsedTime = (finishTime) => {
    const currentDateTime = new Date();
    const elapsedMinutes = differenceInMinutes(currentDateTime, finishTime);
    return Math.abs(elapsedMinutes); // Mutlak değeri alarak her zaman pozitif bir değer döndür
  };

  

  const handleDeleteSiparis = async (siparisId) => {
    try {
      await deleteDoc(doc(db, "orders", siparisId));
      setSiparisler(siparisler.filter((siparis) => siparis.id !== siparisId));
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  return (
    <div className="admin-siparis-container">
      <h1 className="admin-siparis-title">SİPARİŞLER</h1>
      {siparisler.map((masaSiparisi, index) => (
        <div key={index} className="masa-siparis-container">
          {masaSiparisi.orders.length > 0 && (
            <h2 className="masa-no">{masaSiparisi.orders[0].masaNo}</h2>
          )}
          <ul className="siparis-listesi">
            {masaSiparisi.orders.map((siparis, siparisIndex) => (
              <li key={siparisIndex} className="siparis-detay">
                <p className="isim">İsim: {siparis.name}</p>
                <p className="adet">Adet: {siparis.quantity}</p>
                <p className="fiyat">Fiyat: {siparis.totalPrice}</p>
                {siparis.note && <p className="note">Not: {siparis.note}</p>}
              </li>
            ))}
            {masaSiparisi.note && (
              <li className="siparis-detay">
                <p className="order-note">Sipariş Notu: {masaSiparisi.note}</p>
              </li>
            )}
            <li className="siparis-detay">
              <p>
                {calculateElapsedTime(masaSiparisi.finishTime)} dakika önce
                verildi
              </p>
              <p className="adisyon-ucreti">
                Adisyon Ücreti:{" "}
                {masaSiparisi.orders.reduce(
                  (total, siparis) => total + siparis.totalPrice,
                  0
                )}
              </p>
              {masaSiparisi.garsonName && (
                <p className="garson-info">
                  Garson: {masaSiparisi.garsonName} {masaSiparisi.garsonSurname}
                </p>
              )}
              {!masaSiparisi.garsonName && (
                <p className="garson-info">Müşteri tarafından verildi</p>
              )}
              <button className="button-admin" onClick={() => handleDeleteSiparis(masaSiparisi.id)}>
                Siparişi Sil
              </button>
            </li>
          </ul>
        </div>
      ))}
    </div>
  );
};

export default AdminSiparis;
