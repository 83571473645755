import React from "react";
import { Link } from "react-router-dom";
import tavada from "../images/tavada-balik.jpg";
import ızgara from "../images/ızgara-balik.jpg";
import ekmekarasi from "../images/ekmek-arasi-cipura.png";
import "./../css/AlkolAraSayfa.css";
import NavigationBar from "../components/Navbar";
import { useParams } from "react-router-dom";
const BalikAraSayfa = () => {
  const {masa, dil } = useParams();

  return (
    <div>
      <NavigationBar masa={masa} dil={dil} />
      <div className="card-list-alkol">
        <h2>
          {dil === "en"
            ? "FISH"
            : dil === "ru"
            ? "РЫБА"
            : dil === "de"
            ? "FISCHE"
            : dil === "ar"
            ? "سمك"
            : "BALIKLARIMIZ"}
        </h2>
        <Link to={`/ekmekarasi/${masa}/${dil}`} className="custom-card-alkol">
          <img src={ekmekarasi} alt="Kart 2" />
          <div className="card-content-admin">
            <h3>
              {dil === "en"
                ? "Fish Between Bread"
                : dil === "ru"
                ? "Рыба между хлебом"
                : dil === "de"
                ? "Fisch zwischen Brot"
                : dil === "ar"
                ? "السمك بين الخبز"
                : "Ekmek Arası Balık"}
            </h3>
          </div>
        </Link>
        <Link to={`/tavada/${masa}/${dil}`} className="custom-card-alkol">
          <img src={tavada} alt="Kart 3" />
          <div className="card-content-admin">
            <h3>
              {dil === "en"
                ? "Fried Fish"
                : dil === "ru"
                ? "Жареная рыба"
                : dil === "de"
                ? "Gebratener Fisch"
                : dil === "ar"
                ? "سمك مقلى"
                : "Tavada Balık"}
            </h3>
          </div>
        </Link>
        <Link to={`/ızgara/${masa}/${dil}`} className="custom-card-alkol">
          <img src={ızgara} alt="Kart 3" />
          <div className="card-content-admin">
            <h3>
              {dil === "en"
                ? "Grilled fish"
                : dil === "ru"
                ? "Жареная рыба"
                : dil === "de"
                ? "Gegrillten Fisch"
                : dil === "ar"
                ? "السمك المشوي"
                : "Izgara Balık"}
            </h3>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default BalikAraSayfa;
