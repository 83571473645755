import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebase";
import "./../css/Admin.css"; // CSS dosyasını içe aktarın

const Garson = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      // Başarılı giriş sonrasında localStorage'e garsonun email'ini kaydet
      localStorage.setItem("garsonEmail", email);
      // GarsonSiparis sayfasına yönlendirme
      window.location = "/GarsonSiparis";
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="admin-container">
      <h2>Garson Giriş Ekranı</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="email">E-posta:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Şifre:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Giriş Yap</button>
      </form>
      {error && <div className="error-message-admin">{error}</div>}
      <button onClick={() => (window.location = "/GarsonRegister")}>
        Kayıt Ol
      </button>
    </div>
  );
};

export default Garson;
