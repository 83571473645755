import React from "react";
import "./App.css";
import Home from "./pages/home";
import MezePage from "./pages/MezePage";
import AlkolPage from "./pages/AlkolPage";
import IcecekPage from "./pages/IcecekPage";
import Admin from "./pages/Admin";
import AdminYonetim from "./pages/AdminYonetim";
import AdminMenu from "./pages/AdminMenu";
import Masa from "./pages/Masa";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AdminSiparis from "./pages/AdminSiparis";
import SepetPage from "./pages/Sepet";
import KoftePage from "./pages/Kofte";
import MakarnaPage from "./pages/Makarna";
import EtlerPage from "./pages/Etler";
import SalataPage from "./pages/Salata";
import SusiPage from "./pages/Susi";
import CorbaPage from "./pages/Corba";
import AraSicakPage from "./pages/AraSicak";
import BalikDetailPage from "./pages/BalikDetailPage";
import AlkolAraSayfa from "./pages/AlkolAraSayfa";
import BiraPage from "./pages/BiraPage";
import SarapPage from "./pages/SarapPage";
import RakiPage from "./pages/RakiPage";
import BalikAraSayfa from "./pages/BalikAraSayfa";
import EkmekArasiPage from "./pages/EkmekArasi";
import TavadaPage from "./pages/Tavada";
import IzgaraPage from "./pages/Izgara";
import Garson from "./pages/Garson";
import GarsonRegister from "./pages/GarsonRegister";
import GarsonSiparis from "./pages/GarsonSiparis";
import Ciro from "./pages/Ciro";
import AdminMasaSiparis from "./pages/AdminMasaSiparis";
import GunlukCiro from "./pages/GunlukCiro";
import HaftaCiro from "./pages/HaftaCiro";
import AyCiro from "./pages/AyCiro";
import StockPage from "./pages/StockPage";
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Masa />} />
        <Route path="/home/:masa/:dil" element={<Home />} />
        <Route path="/balik/:masa/:dil" element={<BalikAraSayfa />} />
        <Route path="/ekmekarasi/:masa/:dil" element={<EkmekArasiPage />} />
        <Route path="/tavada/:masa/:dil" element={<TavadaPage />} />
        <Route path="/ızgara/:masa/:dil" element={<IzgaraPage />} />
        <Route path="/menu/:masa/:dil/:id" element={<BalikDetailPage />} />
       
        <Route path="/meze/:masa/:dil" element={<MezePage />} />
        {/*<Route path="/kofte" element={<KoftePage />} />*/}
        <Route path="/susi/:masa/:dil" element={<SusiPage />} />
        <Route path="/corba/:masa/:dil" element={<CorbaPage />} />
        <Route path="/arasicak/:masa/:dil" element={<AraSicakPage />} />
        <Route path="/makarna/:masa/:dil" element={<MakarnaPage />} />
        <Route path="/et/:masa/:dil" element={<EtlerPage />} />
        <Route path="/salata/:masa/:dil" element={<SalataPage />} />
        <Route path="/alkol/:masa/:dil" element={<AlkolAraSayfa />} />
        <Route path="/bira/:masa/:dil" element={<BiraPage />} />
        <Route path="/sarap/:masa/:dil" element={<SarapPage />} />
        <Route path="/raki/:masa/:dil" element={<RakiPage />} />
        <Route path="/icecek/:masa/:dil" element={<IcecekPage />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/adminyonetim" element={<AdminYonetim />} />
        <Route path="/adminmenu" element={<AdminMenu />} />
        <Route path="/adminmasasiparis" element={<AdminMasaSiparis />} />
        <Route path="/ciro" element={<Ciro />} />
        <Route path="/gunlukciro" element={<GunlukCiro />} />
        <Route path="/haftalikciro" element={<HaftaCiro />} />
        <Route path="/aylikciro" element={<AyCiro />} />
        <Route path="/sepet/:masa/:dil" element={<SepetPage />} />
        <Route path="/adminsiparis" element={<AdminSiparis />} />
        <Route path="/stockpage" element={<StockPage />} />
        <Route path="/garson" element={<Garson />} />
        <Route path="/GarsonRegister" element={<GarsonRegister />} />
        <Route path="/GarsonSiparis" element={<GarsonSiparis />} />
      </Routes>
    </Router>
  );
};

export default App;
