import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../firebase";
import { doc, setDoc } from "firebase/firestore"; // Firestore fonksiyonlarını import edin
import "./../css/Admin.css"; // CSS dosyasını içe aktarın

const GarsonRegister = () => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleRegister = async (e) => {
    e.preventDefault();
    setError(""); // Önceki hataları temizle

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const userId = userCredential.user.uid;

      // Kullanıcı bilgilerini Firestore'a ekleyin
      await setDoc(doc(db, "staff", userId), {
        name,
        surname,
        email,
      });

      // Başarılı kayıt işleminden sonra yönlendirme yapın
      window.location = "/garson";
    } catch (error) {
      console.error("Kayıt Hatası:", error); // Hata için loglama yapın
      setError(error.message);
    }
  };

  return (
    <div className="admin-container">
      <h2>Kayıt Ol</h2>
      <form onSubmit={handleRegister}>
        <div>
          <label htmlFor="name">İsim:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="surname">Soyisim:</label>
          <input
            type="text"
            id="surname"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="email">E-posta:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="password">Şifre:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Kayıt Ol</button>
      </form>
      {error && <div className="error-message-admin">{error}</div>}
    </div>
  );
};

export default GarsonRegister;
