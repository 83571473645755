import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import "./../css/BalikPage.css";
import { ToastContainer, toast } from "react-toastify"; // Toastify ekleniyor
import "react-toastify/dist/ReactToastify.css"; // Toastify için CSS
import youtube from "./../images/youtube.png";
import sepet from "./../images/cart4.svg";
import NavigationBar from "../components/Navbar";
import { Link } from "react-router-dom";
import styles from "./../css/BalikDetailPage.module.css";
import { useParams } from "react-router-dom";
const AraSicakPage = () => {
  const { masa, dil } = useParams();
  const [araSicakMenu, setaraSicakMenu] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetcharaSicakMenu = async () => {
      try {
        if (!dil) return;

        const q = query(
          collection(db, `menu${dil}`),
          where("category", "==", "Ara Sıcak")
        );
        const querySnapshot = await getDocs(q);
        const menuItems = [];
        querySnapshot.forEach((doc) => {
          menuItems.push({ id: doc.id, ...doc.data(), quantity: 0 });
        });
        setaraSicakMenu(menuItems);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching menu items:", error);
        setLoading(false);
      }
    };

    fetcharaSicakMenu();
  }, [dil]);

  const handleRecipeClick = (url) => {
    window.open(url, "_blank");
  };

  const handleAddToCart = async (menuItem) => {
    try {
      if (menuItem.quantity === 0) {
        toast.error("Lütfen adet giriniz.");
        return;
      }
      const totalPrice = parseInt(menuItem.quantity) * menuItem.price;

      console.log(masa);
      // Masanın son adını cartItem'a ekle
      const cartItem = {
        name: menuItem.name,
        orijinalDil: menuItem.orijinalDil,
        quantity: menuItem.quantity,
        price: menuItem.price,
        totalPrice: totalPrice,
        category: menuItem.category,
        masaNo: masa,
        createdAt: new Date(),
        imageUrl: menuItem.imageUrl,
        processed: false,
        processingItem: false,
      };

      // Cart'a öğe ekle
      await addDoc(collection(db, "cart"), cartItem);
      let message = "";
      if (dil === "en") {
        message = `"${menuItem.name}" added to cart, ${menuItem.quantity} piece(s).`;
      } else if (dil === "ru") {
        message = `"${menuItem.name}" добавлено в корзину, ${menuItem.quantity} штук(и).`;
      } else if (dil === "de") {
        message = `"${menuItem.name}" zum Warenkorb hinzugefügt, ${menuItem.quantity} Stück.`;
      } else if (dil === "ar") {
        message = `"${menuItem.name}" تمت إضافته إلى السلة، ${menuItem.quantity} قطعة.`;
      } else {
        message = `"${menuItem.name}" sepete eklendi, ${menuItem.quantity} adet.`;
      }

      toast.success(message);
    } catch (error) {
      console.error("Error adding to cart:", error);
    }
  };

  const handleQuantityChange = (event, menuItem) => {
    const newQuantity = parseInt(event.target.value);
    const updatedMenu = araSicakMenu.map((item) => {
      if (item.id === menuItem.id) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    setaraSicakMenu(updatedMenu);
  };

  if (loading) {
    return (
      <div className={styles.loading}>
        <div className={styles.spinner}></div>
        <div className={styles.message}>Loading...</div>
      </div>
    );
  }

  return (
    <div>
      <NavigationBar masa={masa} dil={dil} />
      <div className="balik-menu-container">
        <ToastContainer /> {/* Toastify için container ekleniyor */}
        <ul className="menu-list">
          {araSicakMenu.map((menuItem) => (
            <li key={menuItem.id} className="menu-item">
              <div className="menu-list-text-buttons">
                <div className="menu-list-texts">
                  <h2>{menuItem.name}</h2>
                  <p>
                    {menuItem.description.length > 17
                      ? `${menuItem.description.slice(0, 17)}...`
                      : menuItem.description}
                  </p>
                  <span>{menuItem.price}TL</span>
                </div>
                <div className="button">
                  <input
                    type="number"
                    min="0"
                    value={menuItem.quantity}
                    onChange={(event) => handleQuantityChange(event, menuItem)}
                  />
                  <img
                    className="menu-sepet"
                    src={sepet}
                    alt="Sepete Ekle"
                    onClick={() => handleAddToCart(menuItem)}
                  />
                </div>
              </div>
              <div className="menu-list-img">
                <Link to={`/menu/${masa}/${dil}/${menuItem.id}`}>
                  <img
                    src={menuItem.imageUrl}
                    alt={menuItem.name}
                    className="menu-image"
                  />
                </Link>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AraSicakPage;
