import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import menu from "../images/logo.png";
import gecmismenu from "../images/gecmissiparis.webp";
import stok from "../images/stok.png";
import balık from "../images/balık.jpg";
import ciroImage from "../images/ciro.webp"; // Yeni ciro resmi
import styles from "./../css/AdminYonetim.module.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const AdminYonetim = () => {
  const auth = getAuth();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (!user) {
        window.location = "/admin"; // Kullanıcı oturum açmamışsa giriş sayfasına yönlendir
      }
    });

    return () => unsubscribe();
  }, [auth]);

  return (
    <div className={styles.cardList}>
      <div className={styles.cardListTitle}>
        <h1>HOŞGELDİNİZ</h1>
      </div>
      <div className={styles.cardListContentFirst}>
        <Link to="/adminmenu" className={styles.customCard}>
          <img src={balık} alt="Kart 2" />
          <div className={styles.cardContent}>
            <h3>
              <b>MENÜLERİ YÖNET</b>
            </h3>
          </div>
        </Link>

        <Link to="/adminmasasiparis" className={styles.customCard}>
          <img src={menu} alt="Kart 2" />
          <div className={styles.cardContent}>
            <h3>
              <b>SİPARİŞLERİ YÖNET</b>
            </h3>
          </div>
        </Link>

        <Link to="/adminsiparis" className={styles.customCard}>
          <img src={gecmismenu} alt="Kart 3" />
          <div className={styles.cardContent}>
            <h3>
              <b>GEÇMİŞ SİPARİŞLER</b>
            </h3>
          </div>
        </Link>
      </div>
      <div className={styles.cardListContentSecond}>
        <Link to="/ciro" className={styles.customCard}>
          {" "}
          {/* Yeni ciro yönetimi linki */}
          <img src={ciroImage} alt="Kart 4" />
          <div className={styles.cardContent}>
            <h3>
              <b>CİRO YÖNET</b>
            </h3>
          </div>
        </Link>
        <Link to="/stockpage" className={styles.customCard}>
          {" "}
          {/* Yeni ciro yönetimi linki */}
          <img src={stok} alt="Kart 4" />
          <div className={styles.cardContent}>
            <h3>
              <b>STOK YÖNET</b>
            </h3>
          </div>
        </Link>
      </div>

      {/* 
       <Link to="/ciro" className={styles.customCard}>
       <img src={ciroImage} alt="Kart 4" />
        <div className={styles.cardContent}>
          <h3>
            <b>MALİYET YÖNET</b>
          </h3>
        </div>
      </Link> */}
    </div>
  );
};

export default AdminYonetim;
