import React from "react";
import { Link } from "react-router-dom";
import sarap from "../images/sarap.jpeg";
import rakı from "../images/yeni-raki.webp";
import bira from "../images/efes-malt.jpg";
import "./../css/AlkolAraSayfa.css";
import NavigationBar from "../components/Navbar";
import { useParams } from "react-router-dom";
const AlkolAraSayfa = () => {

  const { masa, dil } = useParams();
  

  return (
    <div>
      <NavigationBar masa={masa} dil={dil} />
      <div className="card-list-alkol">
        <h2>
          {dil === "en"
            ? "ALCOHOLIC DRINKS"
            : dil === "ru"
            ? "АЛКОГОЛЬНЫЕ НАПИТКИ"
            : dil === "de"
            ? "ALKOHOLISCHE GETRÄNKE"
            : dil === "ar"
            ? "مشروبات كحولية"
            : "ALKOLLÜ İÇECEKLERİMİZ"}
        </h2>
        <Link to={`/raki/${masa}/${dil}`} className="custom-card-alkol">
          <img src={rakı} alt="Kart 2" />
          <div className="card-content-admin">
            <h3>
              {dil === "en"
                ? "Raki"
                : dil === "ru"
                ? "Раки"
                : dil === "de"
                ? "Raki"
                : dil === "ar"
                ? "راكي"
                : "Rakı"}
            </h3>
          </div>
        </Link>
        <Link to={`/sarap/${masa}/${dil}`} className="custom-card-alkol">
          <img src={sarap} alt="Kart 3" />
          <div className="card-content-admin">
            <h3>
              {dil === "en"
                ? "Wine"
                : dil === "ru"
                ? "Вино"
                : dil === "de"
                ? "Wein"
                : dil === "ar"
                ? "خمر"
                : "Şarap"}
            </h3>
          </div>
        </Link>
        <Link to={`/bira/${masa}/${dil}`} className="custom-card-alkol">
          <img src={bira} alt="Kart 3" />
          <div className="card-content-admin">
            <h3>
              {dil === "en"
                ? "Beer"
                : dil === "ru"
                ? "Пиво"
                : dil === "de"
                ? "Bier"
                : dil === "ar"
                ? "جعة"
                : "Bira"}
            </h3>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default AlkolAraSayfa;
